import CustomGrid from "../../../common/CustomGrid"
import { useFetchGet } from "../../../common/fetchGetApi"
import { LMAXRoutesName, decimalPlace, timeFormat } from "../utils"

let ColumnData = [
    { field: 'id', headerText: 'ID', width: '' },
    { field: 'time', headerText: 'Time', width: '', format: timeFormat },
    { field: 'instrument', headerText: 'Instrument', width: '' },
    { field: 'side', headerText: 'Side', width: '' },
    { field: 'qty', headerText: 'Qty', width: '', format: decimalPlace() },
    { field: 'price', headerText: 'Price', width: '', format: decimalPlace(5) },
    { field: 'state', headerText: 'State', width: '' },
]

function Orders() {
    const { isLoading, apiData = [], serverError } = useFetchGet(LMAXRoutesName.orders)

    return (
        <>
            <CustomGrid ColumnData={ColumnData} apiData={apiData} isLoading={isLoading} />
        </>
    )
}

export default Orders