import moment from 'moment'

export const commonRoute = {
  dashboard: '/dashboard',
  reports: '/reports',
  liquidity: '/liquidity',
}

export let routeName = {
  tradingHeadline: `${commonRoute.dashboard}/tradingheadlines`,
  topExposures: `${commonRoute.dashboard}/topexposures`,
  revenueReports: `${commonRoute.reports}/revenuereports`,
  dashboardLMAX: `${commonRoute.liquidity}/lmax`,
  dashboardFinalto: `${commonRoute.liquidity}/finalto`,
  reconciliation: `${commonRoute.liquidity}/reconciliation`
}

export function currencyFormat(amt) {
  return amt === 'NA' ? 'NA' : `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amt)}` || ' $0.00'
}

export const getTimeStamps = (type) => {
  let startDate = null;
  let endDate = null;
  switch (type) {
    case 'today':
      startDate = moment().startOf('D').unix()
      endDate = moment().unix()
      break;
    case 'lastDay':
      startDate = moment().subtract(1, 'd').unix()
      endDate = moment().unix()
      break;

    case 'last7Days':
      startDate = moment().subtract(7, 'd').startOf('D').unix()
      endDate = moment().unix()
      break;

    case 'last30Days':
      startDate = moment().subtract(30, 'd').startOf('D').unix()
      endDate = moment().unix()
      break;

    case 'last3Months':
      startDate = moment().subtract(3, 'months').startOf('D').unix()
      endDate = moment().unix()
      break;

    default:
      endDate = moment().unix()
      startDate = moment().startOf('D').unix()
      break;
  }
  return { startDate, endDate }
}



export const dateRadioData = [
  {
    label: "Today",
    value: "today",
  },
  {
    label: "24H",
    value: "lastDay",
  },
  {
    label: "7D",
    value: "last7Days",
  },
  {
    label: "30D",
    value: "last30Days",
  },
  {
    label: "3M",
    value: "last3Months",
  },
];

export function unixDateFilterFormat(date) {
  return moment.unix(date).format('YYYY-MM-DD') || '-'
}