import React from 'react'
import CustomPivotTable from '../../../common/CustomPivotTable'
import { dataValue } from './utils'


const symbolDataEdit = (data = []) => data && data.map(data => {
    return { ...data, symbol: data.symbol.split('.').join(' '), login: `${data.login} - ${data.name}` }
})

const columnsName = [dataValue.ProfitOpen, dataValue.NetExposure, dataValue.ProfitOpenPeriod, dataValue.ProfitClosed]


function TopExposureBook({ data, tabName }) {
    return <>
        <CustomPivotTable
            tableData={symbolDataEdit(data)}
            values={[
                { name: dataValue.ProfitOpen, caption: 'Open P/L', },
                { name: dataValue.NetExposure, caption: 'Net Expo USD' },
                { name: dataValue.ProfitOpenPeriod, caption: 'Open P/L Period' },
                { name: dataValue.ProfitClosed, caption: 'Closed P/L' }
            ]}
            rows={[
                { name: dataValue.Book },
                { name: dataValue.Symbol },
                { name: dataValue.Login },
            ]}
            columns={[
                /*{ name: dataValue.Book }*/
            ]}
            showGroupingBar={true}
            columnsName={columnsName}
            sortBy={'Net Expo USD'}
            tabName={tabName}
        />
    </>
}

export default TopExposureBook