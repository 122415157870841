import CustomGrid from "../../../common/CustomGrid"
import { useFetchGet } from "../../../common/fetchGetApi"
import { LMAXRoutesName, decimalPlace } from "../utils"


let ColumnData = [
    { field: 'instrument', headerText: 'Instrument', width: '' },
    { field: 'qty', headerText: 'Qty', width: '', format: decimalPlace() },
    { field: 'openPrice', headerText: 'Open Price', width: '', format: decimalPlace(5) },
    { field: 'currentPrice', headerText: 'Current Price', width: '', format: '' },
    { field: 'pnL', headerText: 'PnL', width: '' },
]

function Positions() {
    const { isLoading, apiData = [], serverError } = useFetchGet(LMAXRoutesName.positions)

    return (
        <>
            <CustomGrid ColumnData={ColumnData} apiData={apiData} isLoading={isLoading} />
        </>
    )
}

export default Positions