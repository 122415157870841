import React from 'react'
import CustomPivotTable from '../../../common/CustomPivotTable'
import { dataValue } from './utils'


const dataUpdate = (pivotData) => pivotData.map(data => {
    return {
        ...data,
        symbol: data.symbol.split('.').join(' '),
        winner: `${data.login} - ${data.name}`,
        game: data.symbolPath,
        total: data.profitOpen + data.profitClosed
    }
})

const columnsName = [dataValue.ProfitOpen, dataValue.NetExposure, dataValue.ProfitOpenPeriod, dataValue.ProfitClosed, dataValue.Total]


function TopExposureWinner({ data, tabName }) {
    return <>
        <CustomPivotTable
            tableData={dataUpdate(data)}
            values={[
                { name: dataValue.ProfitOpen, caption: 'Open P/L', },
                { name: dataValue.NetExposure, caption: ' Net Expo USD' },
                { name: dataValue.ProfitOpenPeriod, caption: 'Open P/L Period' },
                { name: dataValue.ProfitClosed, caption: 'Closed P/L' },
                { name: dataValue.Total, caption: 'Total P/L' }
            ]}
            rows={[
                { name: dataValue.Winner },
                { name: dataValue.Game },
                { name: dataValue.Symbol },
            ]}
            columns={[
                //{ name: dataValue.Book }
            ]}
            showGroupingBar={true}
            columnsName={columnsName}
            sortBy={'Total P/L'}
            tabName={tabName}
        />
    </>
}

export default TopExposureWinner