import * as React from "react";
import { NavLink } from 'react-router-dom';
import "./Header.scss";
import smallLogo from "../../assets/images/logo/S-T-GLOBAL-1-01-03.svg";
// import smallLogo from "../../assets/images/logo/riskimg3.png";
import { routeName } from '../../utils'


function Header() {

  const handleLogOff  = () => {
    localStorage.removeItem("smartRiskAuthToken");
  }

  return (
    <div>
      <div className="container-fluid p-0">
        <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between border-bottom">
                  <a
                      href={routeName.tradingHeadline}
            className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none"
          >
            <img src={smallLogo} alt="SmartRisk" className="navbar-logo" />
          </a>

          <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
            <li>
                          <NavLink to={routeName.tradingHeadline}>
                  <i className="fa fa-address-card"></i>
                    Dashboard
                </NavLink>
            </li>
            <li>
                <NavLink to={routeName.revenueReports}>
                <i className="fa fa-map"></i>
                    Reports
                </NavLink>
            </li>
            <li>
                          <NavLink to={routeName.tradingHeadline}>
                  <i className="fa fa-bell"></i>
                  Rules and Alerts
                </NavLink>
            </li>
            <li>
                          <NavLink to={routeName.tradingHeadline}>
                  <i className="fa fa-cubes"></i>
                  Analysis
                </NavLink>
            </li>
          </ul>

          <div className="col-md-3 text-end">
          <button type="button" className="btn btn-outline-primary me-2 text-white">
            <i className="fa fa-bell"></i>
            </button>

          <button type="button" className="btn btn-outline-primary me-2 text-white">
            <NavLink to="/" className="text-white" onClick={handleLogOff}>
              <i className="fa fa-power-off"></i>
            </NavLink>
          </button>

          </div>
        </header>
      </div>
    </div>
  );
}

export default Header;
