import React from 'react'
import CustomPivotTable from '../../../common/CustomPivotTable'
import { dataValue } from './utils'


const dataUpdate = (pivotData) => pivotData.map(data => {
    return {
        ...data,
        symbol: data.symbol.split('.').join(' '),
        winner: `${data.login} - ${data.name}`,
        game: data.symbolPath
    }
})

const columnsName = [dataValue.VolumeTraded, dataValue.PositionCount, dataValue.TradeCount, dataValue.NetExposure]


function TopExposureTraderVolume({ data, tabName }) {
    return <>
        <CustomPivotTable
            tableData={dataUpdate(data)}
            values={[
                { name: dataValue.VolumeTraded, caption: 'Trade Volume', },
                { name: dataValue.PositionCount, caption: 'Open Position' },
                { name: dataValue.TradeCount, caption: 'Trade Count' },
                { name: dataValue.NetExposure, caption: 'Net Expo USD' }
            ]}
            rows={[
                { name: dataValue.Winner },
                { name: dataValue.Game },
                { name: dataValue.Symbol },
            ]}
            columns={[
                //{ name: dataValue.Book }
            ]}
            showGroupingBar={true}
            columnsName={columnsName}
            sortBy={'Trade Volume'}
            tabName={tabName}

        />
    </>
}

export default TopExposureTraderVolume