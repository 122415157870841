export const SAMPLE_CSS = `
//Value Cells
    .e-pivotview .e-valuescontent {
         text-align: center !important;
    }

    //Columns Headers
    /*.e-pivotview .e-columnsheader {
         text-align: center !important;
    }

    //Rows Headers
    .e-pivotview .e-rowsheader {
         text-align: center !important;
    }*/

    //Rows Headers
    .e-pivotview .e-rowsheader {
         text-align: center !important;
    }*/

    .e-pivotview .e-pivotcell-container .e-headertext {
         text-align: center !important;
    }
    
    .e-pivotview div.e-cellvalue {
          text-align: center !important;
    }

    .e-grid .e-gridcontent tr td {
          text-align: center !important;
    }

    .e-pivotview .e-grid .e-rowsheader {
         text-align: left !important;
    }

    .e-pivotview .e-frozencontent>.e-table {
        width : 300px !important;
    }
    .e-pivotview .e-frozenheader>.e-table{
        width : 300px !important;
    }
    
    //Summary Cells
    /* .e-pivotview .e-summary {
         text-align: center !important;
    }*/`;


export const buttonCSS = "k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary export-button"

export const buttonCSSActive = "k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary export-button activeButton-table"