import moment from "moment";

export const commonRoute = 'finalto/live'

export const FinaltoRoutesName = {
    accountTransaction: `${commonRoute}/account/wallet`,
    positions: `${commonRoute}/grid/open-position`,
    orders: `${commonRoute}/grid/orders/29693`,
    tradesHistory: `${commonRoute}/grid/trades/29693`,
    orderHistory: `${commonRoute}/grid/orders/29693`,
    accountWallet: `${commonRoute}/account/wallet`
}

export const subDate = 20

export function decimalPlace(place = 2) {
    return `N${place}`
}

export const timeFormat = 'yMd';

export const currencyWithSymbol = 'C2'


export function dateFilterFormat(date) {
    return moment(date).format('YYYY-MM-DD') || '-'
}
export function dateSubtract(date, days = subDate) {
    return moment(date).subtract(days, 'days').format('YYYY-MM-DD') || '-'
}