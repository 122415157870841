import React from 'react'
import CustomPivotTable from '../../../common/CustomPivotTable'
import { dataValue } from './utils'


const dataUpdate = (pivotData) => pivotData.map(data => {
    return {
        ...data,
        symbol: data.symbol.split('.').join(' '),
        winner: `${data.login} - ${data.name}`,
        game: data.symbolPath
    }
})

const columnsName = [dataValue.TradeCount, dataValue.ProfitOpen, dataValue.ProfitClosed, dataValue.ProfitOpenPeriod, dataValue.NetExposure]


function TopExposureCountry({ data, tabName }) {
    return <>
        <CustomPivotTable
            tableData={dataUpdate(data)}
            values={[
                { name: dataValue.TradeCount, caption: 'Trade Count' },
                { name: dataValue.ProfitOpen, caption: 'Open P/L', },
                { name: dataValue.ProfitClosed, caption: 'Closed P/L' },
                { name: dataValue.ProfitOpenPeriod, caption: 'Open P/L Period' },
                { name: dataValue.NetExposure, caption: 'Net Expo USD' },
            ]}
            rows={[
                { name: dataValue.Country },
                { name: dataValue.Winner },
                { name: dataValue.Symbol },
            ]}
            columns={[
                //{ name: dataValue.Book }
            ]}
            showGroupingBar={true}
            columnsName={columnsName}
            sortBy={'Trade Count'}
            tabName={tabName}
        />
    </>
}

export default TopExposureCountry