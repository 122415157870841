import CustomGrid from "../../../common/CustomGrid"
import { decimalPlace } from "../utils"


let ColumnData = [
    { field: 'symbol', headerText: 'Symbol', width: '' },
    { field: 'finalto', headerText: 'Finalto', width: '', format: decimalPlace() },
    { field: 'lmax', headerText: 'LMAX', width: '', format: decimalPlace() },
    { field: 'mt5', headerText: 'MT5', width: '' },
    { field: 'total', headerText: 'Total', width: '' },
    { field: 'difference', headerText: 'Difference', width: '' },
]

function AccountWallet({ apiData, isLoading }) {

    return (
        <>
            <CustomGrid ColumnData={ColumnData} apiData={apiData} isLoading={isLoading} allowPaging={true} />
        </>
    )
}

export default AccountWallet