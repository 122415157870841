import React from "react";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { formatNumber } from '@progress/kendo-intl';

const customData = [
  {
    color: "#80cbc4",
  },
  {
    color: "#f48fb1",
  },
];

const ProfitLossCell = (props) => {
  let field;
    let objValue;
  if (props.field.includes(".")) {
    field = props.field.split('.')[1];
    if (Object.hasOwn(props.dataItem, 'trade')) {
      objValue = props.dataItem.trade[field];
    } else {
      objValue = "";
    }
  } else {
    field = props.field || "";
    objValue = props.dataItem[field];
  }

  const navigationAttributes = useTableKeyboardNavigation(props.id);

  return (
    <td
      style={{
        backgroundColor: (objValue > 0) ? customData[0].color : objValue === 0 ? null : customData[1].color,
      }}
      role={"gridcell"}
      aria-colindex={props.ariaColumnIndex}
      {...navigationAttributes}
      >
          {(objValue === null || objValue === undefined) ? "" : formatNumber(objValue, props.format)}
    </td>
  );
};

export default ProfitLossCell;