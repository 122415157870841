import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
// import API from '../../../Api/Config';

//const domain = 'http://20.49.248.204:5000/api';
const domain = '/';

const config = {
  baseURL: domain,
  timeout: 1000000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'referrerPolicy': 'no-referrer-when-downgrade'
  },
  params: {},
};

const API = axios.create(config);
//ASTCard
export const useFetchGetAstCard = (url) => {
  const [isLoadingAstCard, setIsLoadingAstCard] = useState(false);
  const [astcardData, setAstcardData] = useState([]);
  const [serverErrorAstCard, setServerErrorAstCard] = useState(null);
  
  useEffect(() => {
    let cancelRequest = false;
    if (!url || url.includes('undefined')) return;
    const fetchData = async () => {
      setIsLoadingAstCard(true);
      try {
        const resp = await API.get(url);
        const data = (await resp?.data) || [];
        if (cancelRequest) return;
        setAstcardData(data);
        setIsLoadingAstCard(false);
      } catch (error) {
        if (cancelRequest) return;
        setServerErrorAstCard(error);
        setIsLoadingAstCard(false);
      } finally {
        setIsLoadingAstCard(false);
      }
    };

    fetchData();

    return function cleanup() {
      cancelRequest = true;
    };
  }, [url]);

  return { isLoadingAstCard, astcardData, serverErrorAstCard };
};

//AltsCard
export const useFetchGetReportAltsCard = (url) => {
  const [isLoadingAltsCard, setIsLoadingAltsCard] = useState(false);
  const [altsCardData, setAltsCardData] = useState([]);
  const [serverErrorAltsCardData, setServerErrorAltsCardData] = useState(null);
  
  useEffect(() => {
    let cancelRequest = false;
    if (!url || url.includes('undefined')) return;
    const fetchData = async () => {
      setIsLoadingAltsCard(true);
      try {
        const resp = await API.get(url);
        const data = (await resp?.data) || [];
        if (cancelRequest) return;
        setAltsCardData(data);
        setIsLoadingAltsCard(false);
      } catch (error) {
        if (cancelRequest) return;
        serverErrorAltsCardData(error);
        setIsLoadingAltsCard(false);
      } finally {
        setIsLoadingAltsCard(false);
      }
    };

    fetchData();

    return function cleanup() {
      cancelRequest = true;
    };
  }, [url]);

  return { isLoadingAltsCard, altsCardData, serverErrorAltsCardData };
};

//TradsCard
export const useFetchGetReportTradsCard = (url) => {
  const [isLoadingTradsCard, setIsLoadingTradsCard] = useState(false);
  const [tradsCardData, setTradsCardData] = useState([]);
  const [serverErrorTradsCardData, setServerErrorTradsCardData] = useState(null);
  
  useEffect(() => {
    let cancelRequest = false;
    if (!url || url.includes('undefined')) return;
    const fetchData = async () => {
      setIsLoadingTradsCard(true);
      try {
        const resp = await API.get(url);
        const data = (await resp?.data) || [];
        if (cancelRequest) return;
        setTradsCardData(data);
        setIsLoadingTradsCard(false);
      } catch (error) {
        if (cancelRequest) return;
        setServerErrorTradsCardData(error);
        setIsLoadingTradsCard(false);
      } finally {
        setIsLoadingTradsCard(false);
      }
    };

    fetchData();

    return function cleanup() {
      cancelRequest = true;
    };
  }, [url]);

  return { isLoadingTradsCard, tradsCardData, serverErrorTradsCardData };
};

//TotalNetDeposits
export const useFetchGetReportTotalNetDeposits= (url) => {
  const [isLoadingTotalNetDeposits, setIsLoadingTotalNetDeposits] = useState(false);
  const [totalNetDepositsData, setTotalNetDepositsData] = useState([]);
  const [serverErrorTotalNetDepositsData, setServerErrorTotalNetDepositsData] = useState(null);
  
  useEffect(() => {
    let cancelRequest = false;
    if (!url || url.includes('undefined')) return;
    const fetchData = async () => {
      setIsLoadingTotalNetDeposits(true);
      try {
        const resp = await API.get(url);
        const data = (await resp?.data) || [];
        if (cancelRequest) return;
        setTotalNetDepositsData(data);
        setIsLoadingTotalNetDeposits(false);
      } catch (error) {
        if (cancelRequest) return;
        setServerErrorTotalNetDepositsData(error);
        setIsLoadingTotalNetDeposits(false);
      } finally {
        setIsLoadingTotalNetDeposits(false);
      }
    };

    fetchData();

    return function cleanup() {
      cancelRequest = true;
    };
  }, [url]);

  return { isLoadingTotalNetDeposits, totalNetDepositsData, serverErrorTotalNetDepositsData };
};

//ActivityBreakup
export const useFetchGetReportActivityBreakup= (url) => {
  const [isLoadingTotalNetDeposits, setIsLoadingActivityBreakup] = useState(false);
  const [activityBreakupData, setActivityBreakupData] = useState([]);
  const [serverErrorActivityBreakupData, setServerErrorActivityBreakupData] = useState(null);
  
  useEffect(() => {
    let cancelRequest = false;
    if (!url || url.includes('undefined')) return;
    const fetchData = async () => {
      setIsLoadingActivityBreakup(true);
      try {
        const resp = await API.get(url);
        const data = (await resp?.data) || [];
        if (cancelRequest) return;
        setActivityBreakupData(data);
        setIsLoadingActivityBreakup(false);
      } catch (error) {
        if (cancelRequest) return;
        setServerErrorActivityBreakupData(error);
        setIsLoadingActivityBreakup(false);
      } finally {
        setIsLoadingActivityBreakup(false);
      }
    };

    fetchData();

    return function cleanup() {
      cancelRequest = true;
    };
  }, [url]);

  return { isLoadingTotalNetDeposits, activityBreakupData, serverErrorActivityBreakupData };
};

//Alts financial Breakdown
export const useFetchGetAltsFinancialBreakdown= (url) => {
  const [isLoadingAltsFinancialBreakdown, setIsLoadingAltsFinancialBreakdown] = useState(false);
  const [altsFinancialBreakdownData, setAltsFinancialBreakdownData] = useState([]);
  const [serverErrorAltsFinancialBreakdownData, setServerErrorAltsFinancialBreakdownData] = useState(null);
  
  useEffect(() => {
    let cancelRequest = false;
    if (!url || url.includes('undefined')) return;
    const fetchData = async () => {
      setIsLoadingAltsFinancialBreakdown(true);
      try {
        const resp = await API.get(url);
        const data = (await resp?.data) || [];
        if (cancelRequest) return;
        setAltsFinancialBreakdownData(data);
        setIsLoadingAltsFinancialBreakdown(false);
      } catch (error) {
        if (cancelRequest) return;
        setServerErrorAltsFinancialBreakdownData(error);
        setIsLoadingAltsFinancialBreakdown(false);
      } finally {
        setIsLoadingAltsFinancialBreakdown(false);
      }
    };

    fetchData();

    return function cleanup() {
      cancelRequest = true;
    };
  }, [url]);

  return { isLoadingAltsFinancialBreakdown, altsFinancialBreakdownData, serverErrorAltsFinancialBreakdownData };
};


//Particulars
export const useFetchGetParticularsData= (url) => {
  const [isLoadingParticulars, setIsLoadingParticulars] = useState(false);
  const [particularsData, setParticularsData] = useState([]);
  const [serverErrorParticularsData, setServerErrorParticularsData] = useState(null);
  
  useEffect(() => {
    let cancelRequest = false;
    if (!url || url.includes('undefined')) return;
    const fetchData = async () => {
      setIsLoadingParticulars(true);
      try {
        const resp = await API.get(url);
        const data = (await resp?.data) || [];
        if (cancelRequest) return;
        setParticularsData(data);
        setIsLoadingParticulars(false);
      } catch (error) {
        if (cancelRequest) return;
        setServerErrorParticularsData(error);
        setIsLoadingParticulars(false);
      } finally {
        setIsLoadingParticulars(false);
      }
    };

    fetchData();

    return function cleanup() {
      cancelRequest = true;
    };
  }, [url]);

  return { isLoadingParticulars, particularsData, serverErrorParticularsData };
};

//B2B
export const useFetchGetB2bData= (url) => {
  const [isLoadingB2b, setIsLoadingB2b,] = useState(false);
  const [b2bData, setB2bData] = useState([]);
  const [serverErrorB2bData, setServerErrorB2bData] = useState(null);
  
  useEffect(() => {
    let cancelRequest = false;
    if (!url || url.includes('undefined')) return;
    const fetchData = async () => {
      setIsLoadingB2b(true);
      try {
        const resp = await API.get(url);
        const data = (await resp?.data) || [];
        if (cancelRequest) return;
        setB2bData(data);
        setIsLoadingB2b(false);
      } catch (error) {
        if (cancelRequest) return;
        setServerErrorB2bData(error);
        setIsLoadingB2b(false);
      } finally {
        setIsLoadingB2b(false);
      }
    };

    fetchData();

    return function cleanup() {
      cancelRequest = true;
    };
  }, [url]);

  return { isLoadingB2b, b2bData, serverErrorB2bData };
};

//B2C
export const useFetchGetB2cData= (url) => {
  const [isLoadingB2c, setIsLoadingB2c,] = useState(false);
  const [b2cData, setB2cData] = useState([]);
  const [serverErrorB2cData, setServerErrorB2cData] = useState(null);
  
  useEffect(() => {
    let cancelRequest = false;
    if (!url || url.includes('undefined')) return;
    const fetchData = async () => {
      setIsLoadingB2c(true);
      try {
        const resp = await API.get(url);
        const data = (await resp?.data) || [];
        if (cancelRequest) return;
        setB2cData(data);
        setIsLoadingB2c(false);
      } catch (error) {
        if (cancelRequest) return;
        setServerErrorB2cData(error);
        setIsLoadingB2c(false);
      } finally {
        setIsLoadingB2c(false);
      }
    };

    fetchData();

    return function cleanup() {
      cancelRequest = true;
    };
  }, [url]);

  return { isLoadingB2c, b2cData, serverErrorB2cData };
};

//useFetchGetAltsByVolumeData
export const useFetchGetAltsByVolumeData= (url) => {
  const [isLoadingAltsByVolume, setIsLoadingAltsByVolume] = useState(false);
  const [altsByVolumeData, setAltsByVolumeData] = useState([]);
  const [serverErrorAltsByVolumeData, setServerErrorAltsByVolumeData] = useState(null);
  
  useEffect(() => {
    let cancelRequest = false;
    if (!url || url.includes('undefined')) return;
    const fetchData = async () => {
      setIsLoadingAltsByVolume(true);
      try {
        const resp = await API.get(url);
        const data = (await resp?.data) || [];
        if (cancelRequest) return;
        setAltsByVolumeData(data);
        setIsLoadingAltsByVolume(false);
      } catch (error) {
        if (cancelRequest) return;
        setServerErrorAltsByVolumeData(error);
        setIsLoadingAltsByVolume(false);
      } finally {
        setIsLoadingAltsByVolume(false);
      }
    };

    fetchData();

    return function cleanup() {
      cancelRequest = true;
    };
  }, [url]);

  return { isLoadingAltsByVolume, altsByVolumeData, serverErrorAltsByVolumeData };
};

//useFetchGetTradsByVolumeData
export const useFetchGetTradsByVolumeData= (url) => {
  const [isLoadingTradsByVolume, setIsLoadingTradsByVolume] = useState(false);
  const [tradsByVolumeData, setTradsByVolumeData] = useState([]);
  const [serverErrorTradsByVolumeData, setServerErrorTradsByVolumeData] = useState(null);

  useEffect(() => {
    let cancelRequest = false;
    if (!url || url.includes('undefined')) return;
    const fetchData = async () => {
      setIsLoadingTradsByVolume(true);
      try {
        const resp = await API.get(url);
        const data = (await resp?.data) || [];
        if (cancelRequest) return;
        setTradsByVolumeData(data);
        setIsLoadingTradsByVolume(false);
      } catch (error) {
        if (cancelRequest) return;
        setServerErrorTradsByVolumeData(error);
        setIsLoadingTradsByVolume(false);
      } finally {
        setIsLoadingTradsByVolume(false);
      }
    };

    fetchData();

    return function cleanup() {
      cancelRequest = true;
    };
  }, [url]);

  return { isLoadingTradsByVolume, tradsByVolumeData, serverErrorTradsByVolumeData };
};

//useFetchGetAltsByRevenueData
export const useFetchGetAltsByRevenueData= (url) => {
  const [isLoadingAltsByRevenue, setIsLoadingAltsByRevenue] = useState(false);
  const [altsByRevenueData, setAltsByRevenueData] = useState([]);
  const [serverErrorAltsByRevenueData, setServerErrorAltsByRevenueData] = useState(null);

  useEffect(() => {
    let cancelRequest = false;
    if (!url || url.includes('undefined')) return;
    const fetchData = async () => {
      setIsLoadingAltsByRevenue(true);
      try {
        const resp = await API.get(url);
        const data = (await resp?.data) || [];
        if (cancelRequest) return;
        setAltsByRevenueData(data);
        setIsLoadingAltsByRevenue(false);
      } catch (error) {
        if (cancelRequest) return;
        setServerErrorAltsByRevenueData(error);
        setIsLoadingAltsByRevenue(false);
      } finally {
        setIsLoadingAltsByRevenue(false);
      }
    };

    fetchData();

    return function cleanup() {
      cancelRequest = true;
    };
  }, [url]);

  return { isLoadingAltsByRevenue, altsByRevenueData, serverErrorAltsByRevenueData };
};


//useFetchGetTradsByRevenueData
export const useFetchGetTradsByRevenueData= (url) => {
  const [isLoadingTradsByRevenue, setIsLoadingTradsByRevenue] = useState(false);
  const [tradsByRevenueData, setTradsByRevenueData] = useState([]);
  const [serverErrorTradsByRevenueData, setServerErrorTradsByRevenueData] = useState(null);

  useEffect(() => {
    let cancelRequest = false;
    if (!url || url.includes('undefined')) return;
    const fetchData = async () => {
      setIsLoadingTradsByRevenue(true);
      try {
        const resp = await API.get(url);
        const data = (await resp?.data) || [];
        if (cancelRequest) return;
        setTradsByRevenueData(data);
        setIsLoadingTradsByRevenue(false);
      } catch (error) {
        if (cancelRequest) return;
        setServerErrorTradsByRevenueData(error);
        setIsLoadingTradsByRevenue(false);
      } finally {
        setIsLoadingTradsByRevenue(false);
      }
    };

    fetchData();

    return function cleanup() {
      cancelRequest = true;
    };
  }, [url]);

  return { isLoadingTradsByRevenue, tradsByRevenueData, serverErrorTradsByRevenueData };
};


//useFetchGetLossGeneratingAltsData
export const useFetchGetLossGeneratingAltsData= (url) => {
  const [isLoadingLossGeneratingAlts, setIsLoadingLossGeneratingAlts] = useState(false);
  const [lossGeneratingAltsData, setLossGeneratingAltsData] = useState([]);
  const [serverErrorLossGeneratingAltsData, setServerErrorLossGeneratingAltsData] = useState(null);

  useEffect(() => {
    let cancelRequest = false;
    if (!url || url.includes('undefined')) return;
    const fetchData = async () => {
      setIsLoadingLossGeneratingAlts(true);
      try {
        const resp = await API.get(url);
        const data = (await resp?.data) || [];
        if (cancelRequest) return;
        setLossGeneratingAltsData(data);
        setIsLoadingLossGeneratingAlts(false);
      } catch (error) {
        if (cancelRequest) return;
        setServerErrorLossGeneratingAltsData(error);
        setIsLoadingLossGeneratingAlts(false);
      } finally {
        setIsLoadingLossGeneratingAlts(false);
      }
    };

    fetchData();

    return function cleanup() {
      cancelRequest = true;
    };
  }, [url]);

  return { isLoadingLossGeneratingAlts, lossGeneratingAltsData, serverErrorLossGeneratingAltsData };
};

//useFetchGetLossGeneratingTradsData
export const useFetchGetLossGeneratingTradsData= (url) => {
  const [isLoadingLossGeneratingTrads, setIsLoadingLossGeneratingTrads] = useState(false);
  const [lossGeneratingTradsData, setLossGeneratingTradsData] = useState([]);
  const [serverErrorLossGeneratingTradsData, setServerErrorLossGeneratingTradsData] = useState(null);

  useEffect(() => {
    let cancelRequest = false;
    if (!url || url.includes('undefined')) return;
    const fetchData = async () => {
      setIsLoadingLossGeneratingTrads(true);
      try {
        const resp = await API.get(url);
        const data = (await resp?.data) || [];
        if (cancelRequest) return;
        setLossGeneratingTradsData(data);
        setIsLoadingLossGeneratingTrads(false);
      } catch (error) {
        if (cancelRequest) return;
        setServerErrorLossGeneratingTradsData(error);
        setIsLoadingLossGeneratingTrads(false);
      } finally {
        setIsLoadingLossGeneratingTrads(false);
      }
    };

    fetchData();

    return function cleanup() {
      cancelRequest = true;
    };
  }, [url]);

  return { isLoadingLossGeneratingTrads, lossGeneratingTradsData, serverErrorLossGeneratingTradsData };
};


//useFetchGetTradesfinancialbreakdownData
export const useFetchGetTradesfinancialbreakdownData= (url) => {
  const [isLoadingTradsfinancialbreakdownData, setIsLoadingTradsfinancialbreakdownData] = useState(false);
  const [tradsfinancialbreakdownData, setTradsfinancialbreakdownData] = useState([]);
  const [serverErrorTradsfinancialbreakdownData, setServerErrorTradsfinancialbreakdownData] = useState(null);

  useEffect(() => {
    let cancelRequest = false;
    if (!url || url.includes('undefined')) return;
    const fetchData = async () => {
      setIsLoadingTradsfinancialbreakdownData(true);
      try {
        const resp = await API.get(url);
        const data = (await resp?.data) || [];
        if (cancelRequest) return;
        setTradsfinancialbreakdownData(data);
        setIsLoadingTradsfinancialbreakdownData(false);
      } catch (error) {
        if (cancelRequest) return;
        setServerErrorTradsfinancialbreakdownData(error);
        setIsLoadingTradsfinancialbreakdownData(false);
      } finally {
        setIsLoadingTradsfinancialbreakdownData(false);
      }
    };

    fetchData();

    return function cleanup() {
      cancelRequest = true;
    };
  }, [url]);

  return { isLoadingTradsfinancialbreakdownData, tradsfinancialbreakdownData, serverErrorTradsfinancialbreakdownData };
};

//useFetchGetTradesfinancialbreakdownParticularData
export const useFetchGetTradesfinancialbreakdownParticularData= (url) => {
  const [isLoadingTradsfinancialbreakdownParticularData, setIsLoadingTradsfinancialbreakdownParticularData] = useState(false);
  const [tradsfinancialbreakdownParticularData, setTradsfinancialbreakdownParticularData] = useState([]);
  const [serverErrorTradsfinancialbreakdownParticularData, setServerErrorTradsfinancialbreakdownParticularData] = useState(null);

  useEffect(() => {
    let cancelRequest = false;
    if (!url || url.includes('undefined')) return;
    const fetchData = async () => {
      setIsLoadingTradsfinancialbreakdownParticularData(true);
      try {
        const resp = await API.get(url);
        const data = (await resp?.data) || [];
        if (cancelRequest) return;
        setTradsfinancialbreakdownParticularData(data);
        setIsLoadingTradsfinancialbreakdownParticularData(false);
      } catch (error) {
        if (cancelRequest) return;
        setServerErrorTradsfinancialbreakdownParticularData(error);
        setIsLoadingTradsfinancialbreakdownParticularData(false);
      } finally {
        setIsLoadingTradsfinancialbreakdownParticularData(false);
      }
    };

    fetchData();

    return function cleanup() {
      cancelRequest = true;
    };
  }, [url]);

  return { isLoadingTradsfinancialbreakdownParticularData, tradsfinancialbreakdownParticularData, serverErrorTradsfinancialbreakdownParticularData };
};