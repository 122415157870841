import { useState, useRef, useEffect } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import './Lmax.scss'
import { RadioGroup } from "@progress/kendo-react-inputs";
import Positions from "./Tabs/Positions";
import Orders from "./Tabs/Orders";
import HistoryTrades from "./Tabs/HistoryTrades";
import HistoryOrders from "./Tabs/HistoryOrders";
import { useFetchGet } from "../../common/fetchGetApi";
import { LMAXRoutesName, dateFilterFormat, dateSubtract, subDate } from "./utils";
import AccountWallet from "./Tabs/AccountWallet";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "react-bootstrap";
import { dateRadioData, getTimeStamps, unixDateFilterFormat } from "../../utils";

const defaultDate = new Date();
const defaultStartDate = new Date()
const newDefaultStartDate = defaultStartDate.setDate(defaultStartDate.getDate() - subDate);

function LMAXComponent() {
    const [selected, setSelected] = useState(0);
    const [fromDateVal, setFromDateVal] = useState(defaultStartDate);
    const [toDateVal, setToDateVal] = useState(defaultDate);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [radiochecked, setRadiochecked] = useState(dateRadioData[0].value);

    // const { apiData = [] } = useFetchGet(LMAXRoutesName.accountTransaction)
    const { apiData: accountWalletData = [], isLoading } = useFetchGet(LMAXRoutesName.accountWallet)
    const datepickerStartDateRef = useRef(null);
    const datepickerEndDateRef = useRef(null);

    const handleSelect = (e) => {
        setSelected(e.selected);
    };

    useEffect(() => {
        const initialEndDate = new Date();
        setEndTime(dateFilterFormat(initialEndDate));
        setStartTime(dateSubtract(initialEndDate));
    }, []);


    const tabData = [
        // { Component: AccountTransaction, title: 'Account Transaction' },
        { Component: Positions, title: 'Positions' },
        { Component: Orders, title: 'Orders' },
        { Component: HistoryTrades, title: 'History Trades' },
        { Component: HistoryOrders, title: 'History Orders' },
    ]

    const datepickerDateChange = () => {
        let tempStartDate = (datepickerStartDateRef.current.value).toUTCString();
        let tempEndDate = (datepickerEndDateRef.current.value).toUTCString();
        setStartTime(dateFilterFormat(tempStartDate));
        setEndTime(dateFilterFormat(tempEndDate));
    }

    const timePeriodChange = (e) => {
        setRadiochecked(e.value);
        setEndTime(unixDateFilterFormat(getTimeStamps(e.value)?.endDate));
        setStartTime(unixDateFilterFormat(getTimeStamps(e.value)?.startDate));
    };

    return (
        <div className='container' >
            <div>

                <h2 className="heading" >LMAX {accountWalletData?.accountId ? `- ${accountWalletData?.accountId}` : ''} </h2>
                {
                    selected == 3 || selected == 2 ?

                        <div className="row justify-content-end mt-2 mb-3">
                            <div className="radio-form d-flex justify-content-end mb-2">
                                <span className="timelabel">Time Period: </span>
                                <RadioGroup layout={"horizontal"} data={dateRadioData} value={radiochecked} onChange={timePeriodChange} />
                            </div>

                            <div className="col-2 d-flex radio-form ">
                                <span className="timelabel">From: </span>
                                <DatePicker defaultValue={fromDateVal} id="dateFrom" ref={datepickerStartDateRef} />
                            </div>

                            <div className="col-2 d-flex radio-form">
                                <span className="timelabel">To: </span>
                                <DatePicker defaultValue={toDateVal} id="dateTo" ref={datepickerEndDateRef} />
                            </div>

                            <div className="col-1 d-flex radio-form">
                                <Button id="calendarBtn" onClick={datepickerDateChange}>Apply</Button>
                            </div>

                        </div> : null
                }
            </div>

            <AccountWallet apiData={accountWalletData?.wallets} isLoading={isLoading} />

            <TabStrip selected={selected} onSelect={handleSelect} className="mt-5">
                {
                    tabData?.map(tabs => {
                        const { Component, title } = tabs
                        return <TabStripTab title={title} key={title} >
                            <div>
                                <Component tabName={title} startTime={startTime} endTime={endTime} />
                            </div>
                        </TabStripTab>
                    })
                }
            </TabStrip>
        </div>
    )
}

export default LMAXComponent