import React, { useEffect, useState, useRef } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Popup } from "@progress/kendo-react-popup";
import { Loader } from "@progress/kendo-react-indicators";
import { Form, FormElement } from "@progress/kendo-react-form";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "react-bootstrap";
import { useFetchGet } from "../../common/fetchGetApi";
import "./TopExposures.scss";
import TopExposureBook from "./Tabs/Book";
import TopExposureWinner from "./Tabs/Winners";
import TopExposureTraderVolume from "./Tabs/TraderVolume";
import TopExposureInstrument from "./Tabs/Instrument";
import VolumeTradeInstrument from "./Tabs/VolumeTradedByInstrument";
import TopExposureCountry from "./Tabs/Country";
import { dateRadioData,getTimeStamps } from "../../utils";


const tabData = [
  { Component: TopExposureBook, title: 'Top Exposures By Book' },
  { Component: TopExposureWinner, title: 'Top Exposures By Winners' },
  { Component: TopExposureTraderVolume, title: 'Top Exposures By Trade Volume' },
  { Component: TopExposureInstrument, title: 'Top Exposure By Instrument' },
  { Component: VolumeTradeInstrument, title: 'Volume Traded By Instrument' },
  { Component: TopExposureCountry, title: 'Top Exposure By Country' },
]

const TopExposures = (props) => {
  const [selected, setSelected] = useState(0);
  const [apiCustomData, setApiCustomData] = React.useState([]);
  //const { isLoading, apiData, serverError } = useFetchGet('exposure?period=Today');
  // form radio 
  const [radiochecked, setRadiochecked] = useState(dateRadioData[0].value);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');


  // initially set starttime and endtime for gettting data from api 
  useEffect(() => {
    const initialEnddate = new Date();
    const initialUtcEndTime = initialEnddate.toUTCString();

    let initialUnixEndTimestamp = parseInt(Date.parse(initialUtcEndTime).toString().slice(0, -3));
    setEndTime(initialUnixEndTimestamp); // set endtime

    //starttime
    let initialStartDate = new Date();
    initialStartDate.setUTCHours(0);
    initialStartDate.setUTCMinutes(0);
    initialStartDate.setUTCSeconds(0);
    initialStartDate.toUTCString();
    let initialUnixStartTimestamp = parseInt(Date.parse(initialStartDate).toString().slice(0, -3));
    setStartTime(initialUnixStartTimestamp); // set starttime

  }, []);

  const { isLoading, apiData, serverError } = useFetchGet(`riskbackoffice/exposure?limit=1000000&offset=0&start=${startTime}&end=${endTime}&groups=*real*`);

  const defaultdate = new Date();
  const [fromDateVal, setFromDateVal] = useState(new Date('2023-02-01'));
  const [toDateVal, setToDateVal] = useState(new Date('2023-03-01'));
  const datepickerStartDateRef = useRef(null);
  const datepickerEndDateRef = useRef(null);

  const anchor = useRef();
  // show server error
  const [show, setShow] = React.useState(false);
  // fetch data from  headline API
  useEffect(() => {
    let customizedAPIData = [];

    if (!!serverError) {
      setShow(true);
    }

    apiData.forEach(element => {
      let symbolName;
      let symbolString = element['symbolPath'];
      symbolName = symbolString.split('\\')[1];
      element["symbolName"] = symbolName;
      element["symbolGroup"] = element['symbol'] + '-' + element['login'];
      element["traderAccount"] = element['login'] + '-' + element['name'];
      customizedAPIData.push(element);
    });
    setApiCustomData(customizedAPIData);

  }, [apiData, serverError]);

  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  useEffect(() => {
    setSelected(1);
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setSelected(0);
    }, 1000);
  }, [])

  const datepickerDateChange = () => {
    setFromDateVal(datepickerStartDateRef.current.value);
    setToDateVal(datepickerEndDateRef.current.value);
    let tempStartDate = (datepickerStartDateRef.current.value).toUTCString();
    let tempEndDate = (datepickerEndDateRef.current.value).toUTCString();
    let unixStartDate = parseInt(Date.parse(tempStartDate).toString().slice(0, -3));
    let unixEndDate = parseInt(Date.parse(tempEndDate).toString().slice(0, -3));

    // if same date/timestamp then make starttime 1 hour before
    if (unixStartDate == unixEndDate) {
      unixStartDate = unixStartDate - 3600; // set unixStartDate to 60 hour before
    }
    if (unixStartDate < unixEndDate) {
      setStartTime(unixStartDate); // set starttime
      setEndTime(unixEndDate); // set endtime
    }
  }

  const closeErrorPopup = () => {
    setShow(false);
  }

  const timePeriodChange = (e) => {
    setRadiochecked(e.value);
    setEndTime(getTimeStamps(e.value)?.endDate);
    setStartTime(getTimeStamps(e.value)?.startDate);

    //Today
    // if (e.value === "Today") {
    //   // endtime
    //   const enddate = new Date();
    //   const utcEndTime = enddate.toUTCString();

    //   let unixendtimestamp = parseInt(Date.parse(utcEndTime).toString().slice(0, -3));
    //   setEndTime(unixendtimestamp); // set endtime

    //   //starttime
    //   let startdate = new Date();
    //   startdate.setUTCHours(0);
    //   startdate.setUTCMinutes(0);
    //   startdate.setUTCSeconds(0);
    //   startdate.toUTCString();
    //   let unixstarttimestamp = parseInt(Date.parse(startdate).toString().slice(0, -3));
    //   setStartTime(unixstarttimestamp); // set starttime
    // }

    // //24h
    // if (e.value === "LastDay") {
    //   // endtime
    //   const enddate = new Date();
    //   const utcEndTime = enddate.toUTCString();

    //   let unixendtimestamp = parseInt(Date.parse(utcEndTime).toString().slice(0, -3));
    //   setEndTime(unixendtimestamp); // set endtime

    //   //starttime
    //   let startdate = new Date();
    //   startdate.setDate(startdate.getDate() - 1); // yesterday
    //   startdate.toUTCString();
    //   let unixstarttimestamp = parseInt(Date.parse(startdate).toString().slice(0, -3));
    //   setStartTime(unixstarttimestamp); // set starttime
    // }


  };

  const serverAPIError = (
    <Popup
      anchor={anchor.current}
      show={show}
      className={"wrapper"}
      popupClass={"inner-wrapper"}
      id="server-error-popup"
    >
      <span id="closepopup" onClick={closeErrorPopup}>x</span>
      <h5 className="text-center">Server Error</h5>
      <p>Please refresh your page Or Check your Network Connection settings.</p>
    </Popup>
  );

  const TopExposures = (
    <div className="page-content" id="top-exposures">

      <FormElement>
        <div className="row">
          <div className="col-3"><h4>Top Exposures</h4></div>
          <div className="col-9 radio-form d-flex justify-content-end">
            <span className="timelabel">Time Period: </span>
            <RadioGroup layout={"horizontal"} data={dateRadioData} value={radiochecked} onChange={timePeriodChange} />
          </div>
        </div>

        <div className="row justify-content-end">

          <div className="col-2 d-flex radio-form">
            <span className="timelabel">From: </span>
            <DatePicker defaultValue={fromDateVal} id="dateFrom" ref={datepickerStartDateRef} />
          </div>

          <div className="col-2 d-flex radio-form">
            <span className="timelabel">To: </span>
            <DatePicker defaultValue={toDateVal} id="dateTo" ref={datepickerEndDateRef} />
          </div>

          <div className="col-1 d-flex radio-form">
            <Button defaultValue={defaultdate} id="calendarBtn" onClick={datepickerDateChange}>Apply</Button>
          </div>

        </div>
      </FormElement>

      <TabStrip selected={selected} onSelect={handleSelect} className="mt-4">
        {
          tabData?.map(tabs => {
            const { Component, title } = tabs
            return <TabStripTab title={title}>
              <div>
                <Component data={apiCustomData} tabName={title} />
              </div>
            </TabStripTab>
          })
        }
      </TabStrip>
    </div>
  )

  return (
    <div>
      {!isLoading && apiCustomData.length ? TopExposures : (!serverError ? <Loader type="converging-spinner" size="large" /> : (show ? serverAPIError : null))}
    </div>
  );
}

export default TopExposures;

