import { PivotViewComponent, GroupingBar, Inject, ConditionalFormatting, PivotView } from '@syncfusion/ej2-react-pivotview';
import { dataValue } from '../containers/TopExposures/Tabs/utils';
import { useSearchParams } from "react-router-dom";
import useWindowDimensions from './windowWidth';
import { buttonCSS, SAMPLE_CSS, buttonCSSActive } from './PivotTableCSS';
import { useState } from 'react';
import { useEffect } from 'react';

const notCurrency = [dataValue.PositionCount]

const currencyWithNoDecimal = [dataValue.VolumeTraded]

const notCurrencyWithNoDecimal = [dataValue.TradeCount]

function CustomPivotTable({
    tableData,
    values,
    rows,
    height = '800',
    showGroupingBar = false,
    columns,
    columnsName,
    sortBy = "profitOpen",
    tabName
}) {
    const { width } = useWindowDimensions()
    const [searchParams] = useSearchParams();
    const [expand, setExpand] = useState(false)
    const [customTableData, setCustomTableData] = useState([])
    const [selectedBook, setSelectedBook] = useState('All')

    useEffect(() => {
        setCustomTableData(tableData)
    }, [tableData])


    const sidebarVal = searchParams.get('sidebar')

    let pivotObj;

    const conditionalFormatSettings = columnsName?.map(data => ([{
        measure: data,
        value1: 0,
        conditions: 'LessThan',
        style: {
            color: 'black', fontSize: '17px', backgroundColor: '#f48fb1', fontFamily: 'Segoe UI'
        } // #ef3737
    },

    {
        value1: 0,
        measure: data,
        conditions: "GreaterThan",
        style: { color: 'black', fontSize: '17px', backgroundColor: '#80cbc4', fontFamily: 'Segoe UI' } // #18c718
    },
    {
        value1: 0,
        measure: data,
        conditions: "Equals",
        style: { color: '#00000', fontSize: '17px', fontFamily: 'Segoe UI' }
    },
    ])).flat()

    const gridWidth = width - (sidebarVal == 'true' ? 50 : 100)

    function columnRender(args) {
        for (var i = 0; i < args.columns.length; i++) {
            const columnWidth = Math.round(gridWidth / args.columns.length)
            args.columns[i].width = columnWidth - 30; // You can customize the width of specific column here.
        }
    }

    function csvExport() {
        let excelExportProperties = {
            fileName: `${tabName}.xlsx`,
            header: {
                headerRows: 2,
                rows: [
                    { cells: [{ colSpan: 4, value: tabName, style: { fontColor: '#000000', fontSize: 20, hAlign: 'Center', bold: true, underline: true } }] }
                ]
            }
        };
        pivotObj.grid.excelExport(excelExportProperties);
    }

    function exportPDF() {
        let pdfExportProperties = {
            pageOrientation: 'Landscape',
            fileName: `${tabName}.pdf`,
            pageSize: 'A2',
            header: {
                fromTop: 0,
                height: 130,
                contents: [
                    {
                        type: 'Text',
                        value: tabName,
                        position: { x: 0, y: 50 },
                        style: { textBrushColor: '#000000', fontSize: 22, dashStyle: 'Solid', hAlign: 'Center' }
                    }
                ]
            },
            footer: {
                fromBottom: 160,
                height: 150,
                contents: [
                    {
                        type: 'PageNumber',
                        pageNumberType: 'Arabic',
                        format: 'Page {$current} of {$total}',
                        position: { x: 0, y: 25 },
                        style: { textBrushColor: '#000000', fontSize: 15 }
                    }
                ]
            }

        };
        pivotObj.pdfExport(pdfExportProperties);
    }

    const expandAll = () => {
        setExpand(!expand)
    }


    let allBooks = tableData?.map(data => data.book)
    let booksValue = [...new Set(allBooks)];

    const handleClick = (book) => {
        setSelectedBook(book)
        if (book === 'All') {
            setCustomTableData(tableData)
        } else {
            let newData = tableData.filter(data => data.book === book)
            setCustomTableData(newData)
        }

    }

    function headerCellInfo(args) {
        if (
            pivotObj &&
            pivotObj.dataSourceSettings.columns.length === 0 &&
            pivotObj.dataSourceSettings.valueAxis === 'column'
        ) {
            // Customize column header text here.
            args.node.querySelector('.e-headercelldiv .e-headertext').innerText =
                'cdsdc';
        }
    }

    let gridSettings = {
        rowHeight: 40,
        // headerCellInfo: headerCellInfo.bind(this),
        columnRender: columnRender
    };

    return <>

        <div style={{ display: 'flex', justifyContent: 'space-between' }} >
            {
                tabName !== 'Top Exposures By Book' ?
                    <div>
                        {
                            booksValue.map(book => {
                                return <button onClick={() => handleClick(book)} className={`${book == selectedBook ? buttonCSSActive : buttonCSS} `} >Book {book} </button>
                            })
                        }
                        <button onClick={() => handleClick('All')} className={`${selectedBook === 'All' ? buttonCSSActive : buttonCSS} `} >All </button>
                    </div>
                    : <div></div>
            }


            <div>
                <button className={buttonCSS} onClick={expandAll}>{`${expand ? 'Collapse' : 'Expand'} All`}</button>
                <button className={buttonCSS} onClick={csvExport}>CSV Export</button>
                <button className={buttonCSS} onClick={exportPDF}>PDF Export</button>
            </div>
        </div>

        <style>{SAMPLE_CSS}</style>
        <PivotViewComponent ref={d => pivotObj = d} id='PivotView'
            dataSourceSettings={{
                dataSource: customTableData,
                values: values,
                rows: rows,
                formatSettings: columnsName.map(data => ({
                    name: data,
                    format: notCurrency.includes(data) ? 'N2'
                        : currencyWithNoDecimal.includes(data) ? 'C0'
                            : notCurrencyWithNoDecimal.includes(data) ? 'N0'
                                : 'C2',
                    currency: 'USD'
                })),
                columns: columns,
                conditionalFormatSettings: conditionalFormatSettings,
                showGrandTotals: true,
                showColumnGrandTotals: false,
                enableSorting: true,
                allowConditionalFormatting: true,
                expandAll: expand,
                valueSortSettings: {
                    headerText: `${dataValue?.sortingHelperGrandTotal}${sortBy}`,
                    headerDelimiter: ".",
                    sortOrder: "Descending"
                },
            }}
            height={height}
            width={gridWidth}
            gridSettings={gridSettings}
            // showGroupingBar={showGroupingBar}
            enableValueSorting={true}
            allowConditionalFormatting={true}
            allowExcelExport={true}
            allowPdfExport={true}
        >
            <Inject services={[GroupingBar, ConditionalFormatting]} ></Inject>
        </PivotViewComponent>
    </>
}
export default CustomPivotTable