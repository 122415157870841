import * as React from "react";
import "./Sidebar.scss";

import Dropdown from "react-bootstrap/Dropdown";
import { NavLink, useLocation } from "react-router-dom";
import { commonRoute, routeName } from '../../utils'


const sidebarMenu = [
    {
        title: 'Dashboard',
        route: commonRoute.dashboard,

        subRoute: [
            {
                title: 'Trading Headlines',
                route: routeName.tradingHeadline,
                divider: true
            },
            {
                title: 'Top Exposures',
                route: routeName.topExposures,
                divider: false
            },
        ]
    },
    {
        title: 'Reports',
        route: commonRoute.reports,
        subRoute: [
            {
                title: 'Dashboard',
                route: routeName.revenueReports,
                divider: false
            }
        ]
    },
    {
        title: 'Liquidity Providers',
        route: commonRoute.liquidity,
        subRoute: [
            {
                title: 'LMAX',
                route: routeName.dashboardLMAX,
                divider: true
            },
            {
                title: 'Finalto',
                route: routeName.dashboardFinalto,
                divider: true
            },
            {
                title: 'Reconciliation',
                route: routeName.reconciliation,
                divider: false
            }
        ]
    }
]

function Sidebar() {
    const location = useLocation() || ''

    return (
        <div className="dashboard-link" >
            {
                sidebarMenu.map((menu, i) => {
                    return <Dropdown key={i} {...(location.pathname.includes(menu.route) ? { show: location.pathname.includes(menu.route) } : {})}>
                        <Dropdown.Toggle
                            id="dropdown-basic" className={location.pathname.includes(menu.route) && 'dropdown-head'} >{menu.title} </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {
                                menu.subRoute.map((subroute, i) => {
                                    return <>
                                        <Dropdown.Item key={i}>
                                            <NavLink to={subroute.route} className={location.pathname.includes(subroute.route) && 'dropdown-head'} >{subroute.title}</NavLink>
                                        </Dropdown.Item>
                                        {subroute.divider && < Dropdown.Divider style={{ background: 'white' }} />}
                                    </>
                                })
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                })
            }
        </div>
    );
}

export default Sidebar;
