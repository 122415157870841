import './Lmax.scss'
import { useFetchGet } from "../../common/fetchGetApi";
import { LMAXRoutesName, dateSubtract, } from "./utils";
import AccountWallet from "./Tabs/AccountWallet";

function Reconciliation() {
    const { apiData: accountWalletData = [], isLoading } = useFetchGet(LMAXRoutesName.accountWallet)




    return (
        <div className='container' >
            <div>

                <h2 className="heading" >Reconciliation </h2>
            </div>

            <AccountWallet apiData={accountWalletData} isLoading={isLoading} />
        </div>
    )
}

export default Reconciliation