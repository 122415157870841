
import React from "react";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";

const customData = [
    {
      color: "green",
    },
    {
      color: "red",
    },
    {
      color: "black",
    }
  ];

const formatNumber = (val) => {

  if(!!val && val !== 0){
    return (+val).toFixed(2);
  }else if(val === 0){
    return (0).toFixed(2)
  }else{
    return null
  }
    
}  

const GridColorCell = (props) => {
    const field = props.field || "";
    const value = props.dataItem[field];
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    return (
      <td
        style={{
          color: value > 0 ? customData[0].color : (value < 0 ? customData[1].color : customData[2].color ),
        }}
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
        {...navigationAttributes}
      >
        {value === null ? "" :  formatNumber(props.dataItem[field]) } 
      </td>
    );
  };

  

  export default GridColorCell;