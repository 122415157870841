import React from 'react';
import './App.scss';
import Layout from './components/Layout/Layout';
import Routers from './routes';

function App() {

    return (
        <Layout>
            <Routers />
        </Layout>
    );
}

export default App;