import React, { useState } from "react";
import "./Login.scss";
import Form from "react-bootstrap/Form";
import axios from 'axios';


// import bigLogo from "../../assets/images/logo/riskimg3.png";
// import smallLogo from "../../assets/images/logo/riskimg1.svg";

import bigLogo from "../../assets/images/logo/S-T-GLOBAL-1-01-03.svg";
import smallLogo from "../../assets/images/logo/st-gbobal-log.png";

import { useNavigate } from "react-router-dom";
import { routeName } from '../../utils'

function Login() {
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState(null);
  let isLoggedIn = false;
  const [values, setValues] = useState({
    email: '',
    password: ''
  })

  const [validations, setValidations] = useState({
    email: '',
    password: ''
  })

  const validateAll = () => {
    const { email, password } = values
    const validations = { email: '', password: '' }
    let isValid = true

    /* email validations */
    if (!email) {
      validations.email = 'email is required'
      isValid = false
    } else if (email && !/\S+@\S+\.\S+/.test(email)) {
      validations.email = 'email format must be as example@mail.com'
      isValid = false
    }

    /* password validations */
    if (!password) {
      validations.password = 'password is required'
      isValid = false
    }

    if (!isValid) {
      setValidations(validations)
    }

    return isValid
  }

  const validateOne = (e) => {
    const { name } = e.target
    const value = values[name]
    let message = ''

    if (!value) {
      message = `${name} is required`
    }


    if (value && name === 'email' && !/\S+@\S+\.\S+/.test(value)) {
      message = 'Email format must be as example@mail.com'
    }

    setValidations({ ...validations, [name]: message })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  async function postData(url = '', data = {}) {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    return await response;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validateAll();
    if (!isValid) return;

    await postData('/auth/token', values).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        localStorage.removeItem("smartRiskAuthToken");
        isLoggedIn = false;
        setLoginError('Incorrect Username or Password');
        throw new Error('Something went wrong');
      }
    }).then((response) => {
      let authToken = response.token;
      if (authToken) {
        localStorage.setItem("smartRiskAuthToken", authToken);
        isLoggedIn = true;
        setLoginError(null); // TODO: If there is no error, why should we call this function?
        navigate(routeName.tradingHeadline)
      }
    }).catch(function (error) {
      console.log(error);
    });

  }

  const { email, password } = values

  const {
    email: emailVal,
    password: passwordVal
  } = validations


  const handleClick = () => {
    navigate(routeName.tradingHeadline);
  }

  const formMessage = (
    !!loginError ? <div className="alert alert-danger" role="alert">{loginError}</div> : null
  );

  const SideBarTexts = [
    'Summary of Top Trading Headlines.',
    'Various Exposure sections such as By Book, Volume, Instrument, etc.',
    'A comprehensive Reporting section.',
  ]

  return (
    <div className="login">
      <section className="vh-100">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-md-4 col-lg-4 sidebar-lhs">
              <div className="col justify-content-center sidebar-content-wrapper">
                <div className="col-md-12">
                  <div className="big-logo text-center">
                    <img src={bigLogo} alt="SmartRisk" />
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-md-6">
                      <h5 className="fw-bold mb-4 mt-5 text-justify sidebar-txt">
                      Smart way to<br />manage your risk
                      </h5>
                    </div>
                  </div>

                  <div className="row justify-content-center">
                    <div className="col-md-8">
                      <div className="text-center mt-3">
                        <ul className="sidebar-txt highlights-list">
                          {SideBarTexts?.map(data => {
                            return <li> {data} </li>
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="divider d-flex align-items-center my-3">

                  </div>

                  <div className="row justify-content-center">
                    <div className="col-md-6">
                      <h5 className="fw-bold mb-4 mt-4 text-justify sidebar-txt">
                        New to Smart Risk?
                      </h5>
                      <button
                        type="button"
                        className="btn btn-success btn-md signup-btn"
                      >
                        Sign Up
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-md-8 col-lg-8">
              <div className="small-logo text-center">
                <img src={smallLogo} alt="SmartRisk" />
              </div>

              <div className="row justify-content-center">
                <div className="col-md-6 col-lg-5">
                  <Form onSubmit={handleSubmit} noValidate={true}>
                    <h5 className="fw-bold mb-3 pb-3 text-center login-heading">
                      Welcome back to Smart Risk
                    </h5>

                    {formMessage}

                    <Form.Group className="mb-3" controlId="formGroupEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        onBlur={validateOne}
                        placeholder="Your Email Address"
                      />
                      <div className="error-feedback">{emailVal}</div>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGroupPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        value={password}
                        onChange={handleChange}
                        onBlur={validateOne}
                        placeholder="Your Password"
                      />
                      <div className="error-feedback">{passwordVal}</div>
                    </Form.Group>

                    <div className="d-flex justify-content-between align-items-center">
                      <div className="form-check mb-0">
                        <input
                          className="form-check-input me-2"
                          type="checkbox"
                          value=""
                          id="form2Example3"
                        />
                        <label className="form-check-label">
                          Remember me
                        </label>
                      </div>
                      <a href="#!" className="text-body">
                        Forgot password?
                      </a>
                    </div>

                    <div className="text-center text-lg-start mt-4 pt-2">
                      <button
                        type="submit"
                        className="btn btn-success btn-md login-btn"
                      >
                        Login
                      </button>
                      <p className="small fw-bold mt-2 pt-1 mb-0">
                        Don't have an account?{" "}
                        <a href="#!" className="link-danger">
                          Register
                        </a>
                      </p>
                    </div>

                    <div className="divider d-flex align-items-center my-3">
                      <p className="text-center fw-bold mx-3 mb-0">Or</p>
                    </div>

                    <div className="d-flex flex-row align-items-center justify-content-center social-icons text-center">
                      <p className="fw-normal mb-0 me-3">Log in with</p>

                      <button
                        type="button"
                        className="btn btn-primary btn-floating mx-1"
                      >
                        <i className="fa fa-google"></i>
                      </button>

                      <button
                        type="button"
                        className="btn btn-primary btn-floating mx-1"
                      >
                        <i className="fa fa-apple"></i>
                      </button>

                      <button
                        type="button"
                        className="btn btn-primary btn-floating mx-1"
                      >
                        <i className="fa fa-facebook"></i>
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;