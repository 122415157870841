export const dataValue = {
    ProfitOpen: 'profitOpen',
    NetExposure: 'netExposure',
    ProfitOpenPeriod: 'profitOpenPeriod',
    ProfitClosed: 'profitClosed',
    Book: 'book',
    Symbol: 'symbol',
    Login: 'login',
    Winner: 'winner',
    Game: 'game',
    PositionCount: "positionCount",
    TradeCount: "tradeCount",
    VolumeTraded: "volumeTraded",
    Country: "country",
    Total: "total",
    sortingHelperGrandTotal: 'Grand Total.'
}