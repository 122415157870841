import CustomGrid from "../../../common/CustomGrid"
import { decimalPlace } from "../utils"


let ColumnData = [
    // { field: 'currency', headerText: 'Instrument', width: '' },
    { field: 'balance', headerText: 'Balance', width: '', format: decimalPlace() },
    { field: '', headerText: 'PnL', width: '', empty: true },
    { field: '', headerText: 'Margin', width: '', empty: true },
    // { field: 'credit', headerText: 'Credit', width: '', format: decimalPlace() },
    // { field: '', headerText: 'Free Margin', width: '', empty: true },
]

function AccountWallet({ apiData, isLoading }) {

    return (
        <>
            <CustomGrid ColumnData={ColumnData} apiData={apiData} isLoading={isLoading} allowPaging={false} />
        </>
    )
}

export default AccountWallet