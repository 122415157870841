import React, { useEffect, useState } from "react";
import "./Layout.scss";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

function Layout({ children}) {
  const location = useLocation();
  const navigate = useNavigate();
  
  const [isActive, setActive] = useState("false");

  const sidebarCollapse = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    navigate(`${location.pathname}?sidebar=${!isActive}`)
  }, [isActive])

  return (
    <div>

      {location.pathname !== "/" ? <Header /> : ""}

      <div className="container-fluid p-0">
        <div className={location.pathname === "/" ? "container-fluid p-0" : "wrapper"}>
          {location.pathname !== "/" ? (

            <nav id="sidebar" className={isActive ? "active" : null}>
              <Sidebar/>
            </nav>
          ) : null}

          <div className={isActive ? "content" : 'content collapsed'}>
            {location.pathname !== "/" ? (
              <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <button
                  type="button"
                  id="sidebarCollapse"
                  className="btn btn-info"
                  onClick={sidebarCollapse}
                >
                  <i className={isActive ? "fa fa-arrow-right" : "fa fa-arrow-left"}></i>
                  
                </button>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              </nav>
            ) : null}

            <div className="container-fluid p-0">
              {children}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Layout;
