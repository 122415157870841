import { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from "./containers/Login/Login";
import About from "./containers/About/About";
import TradingHeadlines from "./containers/TradingHeadlines/TradingHeadlines";
import TopExposures from "./containers/TopExposures/TopExposures";
import RevenueReports from './containers/Reports/RevenueReports';
import PrivateRoute from "./helpers/PrivateRoute";
import { routeName } from './utils'
import LMAXComponent from './containers/LMAX';
import FinaltoComponent from './containers/Finalto';
import Reconciliation from './containers/Reconciliation';

const Routers = () => {
    return (
        <Fragment>
            <Routes>
                <Route path="/" exact element={<Login />} />
                <Route path="/about" exact element={<About />} />

                <Route exact path={routeName.topExposures} element={<PrivateRoute />}>
                    <Route exact path={routeName.topExposures} element={<TopExposures />} />
                </Route>
                <Route exact path={routeName.tradingHeadline} element={<PrivateRoute />}>
                    <Route exact path={routeName.tradingHeadline} element={<TradingHeadlines />} />
                </Route>
                <Route exact path={routeName.revenueReports} element={<PrivateRoute />}>
                    <Route exact path={routeName.revenueReports} element={<RevenueReports />} />
                </Route>
                <Route exact path={routeName.dashboardLMAX} element={<PrivateRoute />}>
                    <Route exact path={routeName.dashboardLMAX} element={<LMAXComponent />} />
                </Route>
                <Route exact path={routeName.dashboardFinalto} element={<PrivateRoute />}>
                    <Route exact path={routeName.dashboardFinalto} element={<FinaltoComponent />} />
                </Route>
                <Route exact path={routeName.reconciliation} element={<PrivateRoute />}>
                    <Route exact path={routeName.reconciliation} element={<Reconciliation />} />
                </Route>
                {/* <Route path={routeName.dashboardLMAX} exact element={<LMAXComponent />} /> */}
            </Routes>
        </Fragment>
    );
}

export default Routers;