import { useState, useEffect, useRef } from 'react';
import axios from 'axios';


const domain = '/';

const config = {
  baseURL: domain,
  timeout: 1000000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'referrerPolicy': 'no-referrer-when-downgrade'
    // 'Content-Security-Policy': 'upgrade-insecure-requests'
  },
  params: {},
};

function setAuthToken() {
  const token = localStorage.getItem("smartRiskAuthToken");
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  else {
    delete axios.defaults.headers.common["Authorization"];
  }
}

export const useFetchGet = (url) => {
  const [isLoading, setIsLoading] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [serverError, setServerError] = useState(null);

  useEffect(() => {
    let cancelRequest = false;
    if (!url || url.includes('undefined')) return;
    const fetchData = async () => {
      setIsLoading(true);
      try {
        setAuthToken();
        let API = axios.create(config);
        const resp = await API.get(url);
        const data = (await resp?.data) || [];
        if (cancelRequest) return;
        setApiData(data);
        setIsLoading(false);
      } catch (error) {
        if (cancelRequest) return;
        setServerError(error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return function cleanup() {
      cancelRequest = true;
    };
  }, [url]);

  return { isLoading, apiData, serverError };
};