import * as React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Inject, Resize, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import moment from 'moment'
import LoadingSpinner from '../components/LoadingSpinner';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

const priceFields = ['openPrice', 'price', 'currentPrice', 'tradePrice', 'openPL', 'pnL', 'symbol', 'finalto', 'lmax', 'mt5', 'total', 'difference']
const quantity = ['qty', 'side']

function CustomGrid({ ColumnData = [], apiData = [], isLoading = false, allowPaging = true }) {
    let tooltip;

    const beforeRender = (args) => {
        // event triggered before render the tooltip on target element.
        tooltip.content = args.target.closest("td").innerText;
    };

    const fields = { text: 'accountId', value: 'accountId' };

    let indicatorDropDown, gridInstance;
    function indicatorChange(e) {
        if (indicatorDropDown.value === 'Shimmer') {
            gridInstance.loadingIndicator.indicatorType = 'Shimmer';
            gridInstance.refresh();
        }
    }

    function template(props) {
        return props?.time?.length ? moment(props?.time)?.format('DD-MMM-YYYY') || '' : '-'
    }

    function emptyValue(props) {
        return !props?.column?.field ? 'N/A' : '-'
    }

    function gridCustomColors(args, fieldName) {
        let customClassName = args?.cell?.classList

        if (args?.column?.field === fieldName) {

            if (args.data[fieldName] < 0) {
                customClassName.remove('e-increase');
                customClassName.add('e-decrease');
            }
            else if (args.data[fieldName] > 0) {
                customClassName.remove('e-decrease');
                customClassName.add('e-increase');
            }
        }
    }

    const queryCellInfo = (args) => {
        let customClassName = args.cell?.classList
        if (priceFields?.includes(args.column?.field)) {
            priceFields?.map(data => gridCustomColors(args, data))
        }
        if (quantity?.includes(args.column?.field)) {
            const sideValue = args?.data?.['side']
            if (sideValue === 'Sell') {
                customClassName.remove('e-increase');
                customClassName.add('e-decrease');

            } else if (sideValue === 'Buy') {
                customClassName.remove('e-decrease');
                customClassName.add('e-increase');
            }
        }
    }

    const created = (event) => {
        document?.getElementById(indicatorDropDown?.element.id + "_searchbar")?.addEventListener('keyup', () => {
            indicatorDropDown?.search(event?.target?.value);
        });
    };

    return (
        <>
            {
                isLoading ?

                    <LoadingSpinner />
                    :
                    <div className='control-pane'>
                        <div className='control-section'>
                            <TooltipComponent ref={t => tooltip = t} target='.e-rowcell' beforeRender={beforeRender}>
                                <GridComponent id="Grid" dataSource={apiData} allowPaging={allowPaging} pageSettings={{ pageCount: 5, pageSize: 15 }} created={created}
                                    allowResizing={true} autoFit={true} rowHeight={40} allowSorting={true} value="Shimmer" gridLines="Both" queryCellInfo={queryCellInfo}
                                    change={indicatorChange} fields={fields} ref={(indicateDropDown) => indicatorDropDown = indicateDropDown} {...(allowPaging && { toolbar: ['Search'] })}
                                >

                                    <ColumnsDirective>
                                        {
                                            ColumnData?.map(data => {
                                                const { field, headerText, width = '120', format = "", textAlign = "Center", empty = false } = data
                                                return <ColumnDirective field={field} headerText={headerText} width={width} format={format} textAlign={textAlign}
                                                    template={
                                                        field == 'time' ? template
                                                            : empty ? emptyValue
                                                                : null
                                                    }
                                                />
                                            })
                                        }
                                    </ColumnsDirective>
                                    <Inject services={[Page, Resize, Sort, Toolbar]} />
                                </GridComponent>
                            </TooltipComponent>

                        </div>
                    </div>
            }
        </>
    );
}
export default CustomGrid;