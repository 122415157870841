import moment from "moment";


export const LMAXRoutesName = {
    accountWallet: `reconciliation`
}

export const subDate = 20

export function decimalPlace(place = 2) {
    return `N${place}`
}

export const timeFormat = 'yMd';

export const currencyWithSymbol = 'C2'


export function dateFilterFormat(date) {
    return moment(date).format('YYYY-MM-DD') || '-'
}
export function dateSubtract(date, days = subDate) {
    return moment(date).subtract(days, 'days').format('YYYY-MM-DD') || '-'
}