import React, { useState, useEffect } from 'react';

import { Route, Redirect, Navigate, Outlet } from 'react-router-dom';

const useAuth = () => {
  
  const [isAuth, setIsAuth] = useState(true);
  let token = localStorage.getItem("smartRiskAuthToken");
  
  useEffect(()=>{
  !!token ? setIsAuth(true) : setIsAuth(false)
  },[token]);

  return isAuth
}

const PrivateRoute = () => {
  const auth = useAuth();

  return auth ? <Outlet /> : <Navigate to="/" exact/>;
};


export default PrivateRoute;