import React, { useEffect, useState, cloneElement, useRef } from "react";
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { orderBy } from "@progress/kendo-data-query";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "react-bootstrap";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { setExpandedState, setGroupIds } from '@progress/kendo-react-data-tools';
import { RadioGroup, Switch } from "@progress/kendo-react-inputs";
import { format, formatNumber } from '@progress/kendo-intl';
import { Loader } from "@progress/kendo-react-indicators";
import { Popup } from "@progress/kendo-react-popup";
import { useFetchGet } from "../../common/fetchGetApi";
import ProfitLossCell from "../../components/ProfitLossCell/ProfitLossCell";
import './TradingHeadlines.scss';
import { dateRadioData, getTimeStamps, routeName } from '../../utils'


const initialDataState = {
  take: 10,
  skip: 0,
  group: [{
    field: 'book'
  }]
};

const aggregates = [{
  field: 'profitTotal',
  aggregate: 'sum'
}, {
  field: 'profitClosed',
  aggregate: 'sum'
},
{
  field: 'profitOpen',
  aggregate: 'sum'
}, {
  field: 'volumeTraded',
  aggregate: 'sum'
}, {
  field: 'tradeCount',
  aggregate: 'sum'
}, {
  field: 'clientTradingStats',
  aggregate: 'sum'
}, {
  field: 'depositTotal',
  aggregate: 'sum'
}, {
  field: 'withdrawalTotal',
  aggregate: 'sum'
}
];

var headerValues = null;
// show aggregates of values in table rows
//  var groupsRowsDataValues = null;

const processWithGroups = (data, dataState) => {
  const groups = dataState.group;

  if (groups) {
    groups.map(group => group.aggregates = aggregates);
  }

  dataState.group = groups;
  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group
  });

  // set aggregates data to display individual rows
  // groupsRowsDataValues = newDataState.data;

  // find out header sum values
  getHeaderSumValues(newDataState);

  return newDataState;
};

// function for creating header values
const getHeaderSumValues = (newDataState) => {
  let tempHeaderValues = {
    profitTotal: 0,
    profitClosed: 0,
    profitOpen: 0,
    tradeCount: 0,
    volumeTraded: 0,
    clientTradingStats: 0,
    depositTotal: 0,
    withdrawalTotal: 0
  };

  newDataState.data.forEach(element => {
    tempHeaderValues.profitTotal += element.aggregates.profitTotal.sum;
    tempHeaderValues.profitClosed += element.aggregates.profitClosed.sum;
    tempHeaderValues.profitOpen += element.aggregates.profitOpen.sum;
    tempHeaderValues.tradeCount += element.aggregates.tradeCount.sum;
    tempHeaderValues.volumeTraded += element.aggregates.volumeTraded.sum;
    tempHeaderValues.clientTradingStats += element.aggregates.clientTradingStats.sum;
    tempHeaderValues.depositTotal += element.aggregates.depositTotal.sum;
    tempHeaderValues.withdrawalTotal += element.aggregates.withdrawalTotal.sum;

  });

  headerValues = tempHeaderValues;
}

const TradingHeadlines = (props) => {
  const [topHeadlinesApiData, setTopHeadlinesApiData] = useState(null);
  // form radio 
  const [radiochecked, setRadiochecked] = useState(dateRadioData[0].value);
  // for switch
  const [headlineCategory, setHeadlineCategory] = useState(false);

  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  // initially set starttime and endtime for gettting data from api 
  useEffect(() => {
    const initialEnddate = new Date();
    const initialUtcEndTime = initialEnddate.toUTCString();

    let initialUnixEndTimestamp = parseInt(Date.parse(initialUtcEndTime).toString().slice(0, -3));
    setEndTime(initialUnixEndTimestamp); // set endtime

    //starttime
    let initialStartDate = new Date();
    initialStartDate.setUTCHours(0);
    initialStartDate.setUTCMinutes(0);
    initialStartDate.setUTCSeconds(0);
    initialStartDate.toUTCString();
    let initialUnixStartTimestamp = parseInt(Date.parse(initialStartDate).toString().slice(0, -3));
    setStartTime(initialUnixStartTimestamp); // set starttime

  }, []);

  //sort
  const initialSort = [
    {
      field: "profitTotal",
      dir: "asc",
    },
  ];
  const [sort, setSort] = React.useState([initialSort]);

  //const { isLoading, apiData, serverError } = useFetchGet(`headline/${!headlineCategory ? 'book' : 'asset'}?period=${radiochecked}&groups=*`);
  const { isLoading, apiData, serverError } = useFetchGet(`riskbackoffice/headline/${!headlineCategory ? 'book' : 'asset'}?start=${startTime}&end=${endTime}&groups=*real*`);
  const defaultdate = new Date();
  const [fromDateVal, setFromDateVal] = useState(defaultdate);
  const [toDateVal, setToDateVal] = useState(defaultdate);
  const datepickerStartDateRef = useRef(null);
  const datepickerEndDateRef = useRef(null);
  const anchor = useRef();
  const [show, setShow] = React.useState(false);

  useEffect(() => {
    let customizedAPIData = [];

    if (!!serverError) {
      setShow(true);
    }


    apiData.length && apiData &&
      apiData.forEach(element => {
        //symbolName =symbolString.split('\\')[1];
        element["profitTotal"] = element['profitOpen'] + element['profitClosed'];
        element["averageTrade"] = !!(element['volumeTraded'] / element['tradeCount']) ? (element['volumeTraded'] / element['tradeCount']) : 0;
        element["symbolGroup"] = element['login'] + '-' + element['name'];
        element["clientTradingStats"] = element['tradeProfitableCount'] ? (element['tradeProfitableCount'] / element['tradeCount']) * 100 : 0;
        customizedAPIData.push(element);
      });
    // remove slice here to get all the data. now taking first 5000 recods
    setTopHeadlinesApiData(customizedAPIData);

  }, [apiData, serverError]);

  const [dataState, setDataState] = useState(initialDataState);

  // API data
  let exposuresData = topHeadlinesApiData !== null ? topHeadlinesApiData : [];

  const [result, setResult] = useState(processWithGroups(exposuresData, initialDataState));
  const [collapsedState, setCollapsedState] = useState([]);

  const dataStateChange = event => {
    const newDataState = processWithGroups(exposuresData, event.dataState);

    setResult(newDataState);
    setDataState(event.dataState);
  };

  const expandChange = event => {
    const item = event.dataItem;

    if (item.groupId) {
      const newCollapsedIds = !event.value ? [...collapsedState, item.groupId] : collapsedState.filter(groupId => groupId !== item.groupId);
      setCollapsedState(newCollapsedIds);
    }
  };

  const NumberFormatter = (value, decimal) => {
    return parseFloat(parseFloat(value).toFixed(decimal)).toLocaleString(
      "en-IN",
      {
        useGrouping: true,
      }
    );
  };

  const cellRender = (td, props) => {
    if (td && td.props.children && props.rowType === "groupHeader") {
      let children = (
        <span>
          {td.props.children.props.children}
        </span>
      );
      return cloneElement(td, td.props, children);
    }
    return td;
  };

  const newData = setExpandedState({
    data: result.data,
    collapsedIds: collapsedState
  });

  const columnHeader = (props) => {
    let field = props.field;
    //let fixnumber = +(newData[0].aggregates[field].sum.toFixed(0));
    //headerValues
    let fixnumber = +(headerValues[field].toFixed(0));
    let displayWithCurrency = format('{1:c}', [new Date(), fixnumber]);
    let formattedNumber = formatNumber(displayWithCurrency, "##,#");
    var aggregateColumnVal = formattedNumber.substr(0, formattedNumber.indexOf('.'));

    let colorClass = fixnumber > 0 ? 'green' : 'red';

    return (
      <span>
        {props.children}
        <a className="k-link" onClick={props.onClick} href={routeName.tradingHeadline}>
          <span />
          <span>
            {props.title}
          </span>
          <br />
          <span></span>
          <span className={'totalAmount'.concat(' ' + colorClass)}>
            {aggregateColumnVal}
          </span>
        </a>
      </span>
    );
  };

  const datepickerDateChange = () => {
    setFromDateVal(datepickerStartDateRef.current.value);
    setToDateVal(datepickerEndDateRef.current.value);
    let tempStartDate = (datepickerStartDateRef.current.value).toUTCString();
    let tempEndDate = (datepickerEndDateRef.current.value).toUTCString();
    let unixStartDate = parseInt(Date.parse(tempStartDate).toString().slice(0, -3));
    let unixEndDate = parseInt(Date.parse(tempEndDate).toString().slice(0, -3));

    // if same date/timestamp then make starttime 1 hour before
    if (unixStartDate == unixEndDate) {
      unixStartDate = unixStartDate - 3600; // set unixStartDate to 60 hour before
    }
    if (unixStartDate < unixEndDate) {
      setStartTime(unixStartDate); // set starttime
      setEndTime(unixEndDate); // set endtime
    }
  }

  const closeErrorPopup = () => {
    setShow(false);
  }

  const timePeriodChange = (e) => {
    setRadiochecked(e.value);
    setEndTime(getTimeStamps(e.value)?.endDate);
    setStartTime(getTimeStamps(e.value)?.startDate);

    // if (e.value === "Today") {

    //   const enddate = new Date();
    //   const utcEndTime = enddate.toUTCString();

    //   let unixendtimestamp = parseInt(Date.parse(utcEndTime).toString().slice(0, -3));
    //   setEndTime(unixendtimestamp);


    //   let startdate = new Date();
    //   startdate.setUTCHours(0);
    //   startdate.setUTCMinutes(0);
    //   startdate.setUTCSeconds(0);
    //   startdate.toUTCString();
    //   let unixstarttimestamp = parseInt(Date.parse(startdate).toString().slice(0, -3));
    //   setStartTime(unixstarttimestamp);
    // }


    // if (e.value === "LastDay") {

    //   const enddate = new Date();
    //   const utcEndTime = enddate.toUTCString();

    //   let unixendtimestamp = parseInt(Date.parse(utcEndTime).toString().slice(0, -3));
    //   setEndTime(unixendtimestamp);


    //   let startdate = new Date();
    //   startdate.setDate(startdate.getDate() - 1);
    //   startdate.toUTCString();
    //   let unixstarttimestamp = parseInt(Date.parse(startdate).toString().slice(0, -3));
    //   setStartTime(unixstarttimestamp);

    // }

  };

  const handleToggleSwitch = () => {
    setHeadlineCategory(!headlineCategory);
    setFromDateVal(datepickerStartDateRef.current.value);
    setToDateVal(datepickerEndDateRef.current.value);
  };

  const serverAPIError = (
    <Popup
      anchor={anchor.current}
      show={show}
      className={"wrapper"}
      popupClass={"inner-wrapper"}
      id="server-error-popup"
    >
      <span id="closepopup" onClick={closeErrorPopup}>x</span>
      <h5 className="text-center">Server Error</h5>
      <p>Please refresh your page Or Check your Network Connection settings.</p>
    </Popup>
  );

  const TradingHeadlinesContent = (headlineCategory) => (
    <div className="page-content">

      <Form id="TradingHeadlinesForm" render={(formRenderProps) => (
        <FormElement>
          <div className="row">
            <div className="col-3"><h4>Trading Headlines</h4></div>
            <div className="col-9 radio-form d-flex justify-content-end">
              <span className="timelabel">Time Period: </span>
              <RadioGroup layout={"horizontal"} data={dateRadioData} value={radiochecked} onChange={timePeriodChange} />
              <span className="switchlabel book">Book</span>
              <Switch onLabel={"Book"} offLabel={"Asset"} onChange={handleToggleSwitch} headlineCategory={headlineCategory} checked={headlineCategory} />
              <span className="switchlabel asset">Asset</span>
            </div>
          </div>

          <div className="row justify-content-end mt-2 mb-3">

            <div className="col-2 d-flex radio-form">
              <span className="timelabel">From: </span>
              <DatePicker defaultValue={fromDateVal} id="dateFrom" ref={datepickerStartDateRef} />
            </div>

            <div className="col-2 d-flex radio-form">
              <span className="timelabel">To: </span>
              <DatePicker defaultValue={toDateVal} id="dateTo" ref={datepickerEndDateRef} />
            </div>

            <div className="col-1 d-flex radio-form">
              <Button defaultValue={defaultdate} id="calendarBtn" onClick={datepickerDateChange}>Apply</Button>
            </div>

          </div>
        </FormElement>
      )}
      />

      <div>
        {!headlineCategory ? <Grid style={{
          // height: '325px',
          width: "none",
          display: 'block'
        }} resizable={true} reorderable={true} groupable={{
          footer: 'visible'
        }} data={exposuresData} onDataStateChange={dataStateChange} {...dataState} onExpandChange={expandChange} expandField="expanded" cellRender={cellRender}>
          <Column field="book" title="Book Name" width="160px" />
          <Column field="profitTotal" title="Company Total PNL" format="c" width="180px" cell={ProfitLossCell} />
          <Column field="profitClosed" title="Company Closed PNL" format="c" width="180px" cell={ProfitLossCell} />
          <Column field="profitOpen" title="Company Open PNL" format="c" filter="numeric" width="160px" cell={ProfitLossCell} />
          <Column field="volumeTraded" title="Traded Volume" format="c0" filter="numeric" width="160px" cell={ProfitLossCell} />
          <Column field="clientTradingStats" title="Client Trading Stats" width="180px" cell={ProfitLossCell} />
          <Column field="tradeCount" title="Deals" width="120px" cell={ProfitLossCell} />
          <Column field="depositTotal" title="Deposit" width="120px" cell={ProfitLossCell} />
          <Column field="withdrawalTotal" title="Total Withdrawal" width="160px" cell={ProfitLossCell} />
        </Grid> :

          <Grid style={{
            // height: '325px',
            width: "none",
            display: 'block'
          }} resizable={true} reorderable={true} sortable={true} sort={sort} data={orderBy(exposuresData, sort)} onSortChange={(e) => {
            setSort(e.sort);
          }} onDataStateChange={dataStateChange} {...dataState} onExpandChange={expandChange} expandField="expanded" cellRender={cellRender}>
            <Column field="symbol" title="Symbol" width="280px" />
            <Column field="profitTotal" title="Company Total PNL" format="c" width="200px" cell={ProfitLossCell} />
            <Column field="profitClosed" title="Company Closed PNL" format="c" width="180px" cell={ProfitLossCell} />
            <Column field="profitOpen" title="Company Open PNL" format="c" filter="numeric" width="160px" cell={ProfitLossCell} />
            <Column field="volumeTraded" title="Traded Volume" format="c0" filter="numeric" width="180px" cell={ProfitLossCell} />
            <Column field="clientTradingStats" title="Client Trading Stats" width="180px" cell={ProfitLossCell} />
            <Column field="tradeCount" title="Deals" width="120px" cell={ProfitLossCell} />
          </Grid>

        }
      </div>


      <br />
      <div className="trading-headlinges-block-structure" style={{ display: "none" }}>
        <div className="row m-0" style={{ color: "#ccc" }}>
          <div className="item-wrapper p-1">
            <span className="block-title">{headlineCategory ? 'Asset Name' : 'Book Name'}</span>
          </div>

          <div className="item-wrapper p-1">
            <span className="block-title">Company Total PNL</span>
            <span className={headerValues['profitTotal'] > 0 ? 'amount green' : (headerValues['profitTotal'] < 0 ? 'amount red' : 'amount')}>
              ${NumberFormatter(headerValues['profitTotal'], 2)}</span>
          </div>

          <div className="item-wrapper p-1">
            <span className="block-title">Company closed PNL</span>
            <span className={headerValues['profitClosed'] > 0 ? 'amount green' : (headerValues['profitClosed'] < 0 ? 'amount red' : 'amount')}>
              ${NumberFormatter(headerValues['profitClosed'], 2)}</span>
          </div>
          <div className="item-wrapper p-1">
            <span className="block-title">Company Open PNL</span>
            <span className={headerValues['profitOpen'] > 0 ? 'amount green' : (headerValues['profitOpen'] < 0 ? 'amount red' : 'amount')}>
              ${NumberFormatter(headerValues['profitOpen'], 2)}
            </span>
          </div>
          <div className="item-wrapper p-1">
            <span className="block-title">Traded Vol. Nominal </span>
            <span className={headerValues['volumeTraded'] > 0 ? 'amount green' : (headerValues['volumeTraded'] < 0 ? 'amount red' : 'amount')}>
              {NumberFormatter(headerValues['volumeTraded'], 0)}
            </span>
          </div>
          <div className="item-wrapper p-1">
            <span className="block-title">Client Trading Stats </span>
            <span className={headerValues['clientTradingStats'] > 0 ? 'amount green' : (headerValues['clientTradingStats'] < 0 ? 'amount red' : 'amount')}>
              {NumberFormatter(headerValues['clientTradingStats'], 2)}%
            </span>
          </div>
          <div className="item-wrapper p-1">
            <span className="block-title">Deals </span>
            <span className={headerValues['tradeCount'] > 0 ? 'amount green' : (headerValues['tradeCount'] < 0 ? 'amount red' : 'amount')}>
              {NumberFormatter(headerValues['tradeCount'], 0)}
            </span>
          </div>
          {
            !headlineCategory && <div className="item-wrapper p-1" >
              <span className="block-title">Deposit </span>
              <span className={headerValues['depositTotal'] > 0 ? 'amount green' : (headerValues['depositTotal'] < 0 ? 'amount red' : 'amount')}>
                ${NumberFormatter(headerValues['depositTotal'], 2)}
              </span>
            </div>
          }
          {
            !headlineCategory && <div className="item-wrapper p-1">
              <span className="block-title">Withdrawal </span>
              <span className={headerValues['withdrawalTotal'] > 0 ? 'amount green' : (headerValues['withdrawalTotal'] < 0 ? 'amount red' : 'amount')}>
                ${NumberFormatter(headerValues['withdrawalTotal'], 2)}
              </span>
            </div>
          }

        </div>

        {exposuresData.map(el => {

          return (
            <div className="row content-row m-0" key={el.book}>

              <div className="item-wrapper p-1">
                <span className="amount">
                  {!headlineCategory && el.book}
                  {headlineCategory && el.symbol}
                  =&gt;
                </span>
              </div>

              <div className="item-wrapper p-1">
                <span className="amount">
                  ${NumberFormatter(el.profitTotal, 2)}
                </span>
              </div>

              <div className="item-wrapper p-1">
                <span className="amount">
                  ${NumberFormatter(el.profitClosed, 2)}
                </span>
              </div>

              <div className="item-wrapper p-1">
                <span className="amount">
                  ${NumberFormatter(el.profitOpen, 2)}
                </span>
              </div>

              <div className="item-wrapper p-1">
                <span className="amount">
                  {NumberFormatter(el.volumeTraded, 2)}
                </span>
              </div>

              <div className="item-wrapper p-1">
                <span className="amount">
                  {NumberFormatter(el.clientTradingStats, 2)}%
                </span>
              </div>

              <div className="item-wrapper p-1">
                <span className="amount">
                  {NumberFormatter(el.tradeCount, 2)}
                </span>
              </div>

              {
                !headlineCategory && <div className="item-wrapper p-1">
                  <span className="amount">
                    ${el && el.depositTotal && NumberFormatter(el.depositTotal, 2)}
                  </span>
                </div>
              }

              {
                !headlineCategory && <div className="item-wrapper p-1">
                  <span className="amount">
                    ${el && el.withdrawalTotal && NumberFormatter(el.withdrawalTotal, 2)}
                  </span>
                </div>
              }

            </div>
          );
        })}


      </div>

    </div>
  );

  return (
    <div>
      {!isLoading && exposuresData.length ? TradingHeadlinesContent(headlineCategory) : (!serverError ? <Loader type="converging-spinner" size="large" /> : (show ? serverAPIError : null))}

    </div>
  );
};

export default TradingHeadlines;