import CustomGrid from "../../../common/CustomGrid"
import { useFetchGet } from "../../../common/fetchGetApi"
import { LMAXRoutesName, decimalPlace, timeFormat } from "../utils"


let ColumnData = [
    { field: 'id', headerText: 'ID', width: '210' },
    { field: 'orderId', headerText: 'Order ID', width: '220' },
    { field: 'time', headerText: 'Time', width: '140', format: timeFormat },
    { field: 'instrument', headerText: 'Instrument', width: '135' },
    { field: 'side', headerText: 'Side', width: '135' },
    { field: 'qty', headerText: 'Qty', width: '115', format: decimalPlace() },
    { field: 'price', headerText: 'Price', width: '115', format: decimalPlace(5) },
    { field: 'commission', headerText: 'Commission', width: '125' },
]


function HistoryTrades({ startTime, endTime }) {
    const { isLoading, apiData = [], serverError } = useFetchGet(`${LMAXRoutesName.tradesHistory}?starttime=${startTime}&endtime=${endTime}`)

    return (
        <>
            <CustomGrid ColumnData={ColumnData} apiData={apiData} isLoading={isLoading} />
        </>
    )
}

export default HistoryTrades