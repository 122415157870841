import { useMemo } from "react";
import CustomGrid from "../../../common/CustomGrid"
import { useFetchGet } from "../../../common/fetchGetApi"
import { FinaltoRoutesName, decimalPlace, timeFormat } from "../utils"
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Inject, Resize, Sort } from '@syncfusion/ej2-react-grids';
import moment from "moment";

let ColumnData = [
    { field: 'orderId', headerText: 'ID', width: '' },
    { field: 'time', headerText: 'Time', width: '', format: timeFormat },
    { field: 'instrument', headerText: 'Instrument', width: '' },
    { field: 'side', headerText: 'Side', width: '' },
    { field: 'qty', headerText: 'Qty', width: '', format: decimalPlace(), },
    { field: 'price', headerText: 'Price', width: '', format: decimalPlace(5) },
    { field: 'state', headerText: 'State', width: '', },
]

function Orders({ startTime, endTime }) {
    const { isLoading, apiData = [], serverError } = useFetchGet(`${FinaltoRoutesName.orders}`)

    const customApiData = useMemo(() => {
        const newApiData = apiData?.orders?.map((data) => ({ ...data, time: moment(data.tradeDate).toISOString(), orderId: data.orderId ? String(data.orderId) : '-' }))
        return newApiData || []
    }, [apiData])

    return (
        <>
            <CustomGrid ColumnData={ColumnData} apiData={customApiData} isLoading={isLoading} />
        </>
    )
}

export default Orders