import * as React from "react";
import { useState } from "react";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import GridColorCell from "../../components/GridColorCell/GridColorCell";
import "./RevenueReports.scss";
import {
    useFetchGetAstCard,
    useFetchGetReportAltsCard,
    useFetchGetReportTradsCard,
    useFetchGetReportTotalNetDeposits,
    useFetchGetReportActivityBreakup,
    useFetchGetAltsFinancialBreakdown,
    useFetchGetParticularsData,
    useFetchGetB2bData,
    useFetchGetB2cData,
    useFetchGetAltsByVolumeData,
    useFetchGetTradsByVolumeData,
    useFetchGetAltsByRevenueData,
    useFetchGetTradsByRevenueData,
    useFetchGetLossGeneratingAltsData,
    useFetchGetLossGeneratingTradsData,
    useFetchGetTradesfinancialbreakdownData,
    useFetchGetTradesfinancialbreakdownParticularData,
} from "../../common/fetchGetApi-Report";

const RevenueReports = (props) => {
    const { isLoadingAstCard, astcardData, serverErrorAstCard } = useFetchGetAstCard(`riskbackofficereports/astcard`);
    const { isLoadingAltsCard, altsCardData, serverErrorAltsCardData } = useFetchGetReportAltsCard(`riskbackofficereports/football/altscard`);
    const { isLoadingTradsCard, tradsCardData, serverErrorTradsCardData } = useFetchGetReportTradsCard(`riskbackofficereports/tradescard`);
    const { isLoadingTotalNetDeposits, totalNetDepositsData, serverErrorTotalNetDepositsData} = useFetchGetReportTotalNetDeposits(`riskbackofficereports/totalnetdeposits`);
    const {isLoadingActivityBreakup,activityBreakupData,serverErrorActivityBreakupData} = useFetchGetReportActivityBreakup(`riskbackofficereports/activitybreakup`);
    const {isLoadingAltsFinancialBreakdown,altsFinancialBreakdownData,serverErrorAltsFinancialBreakdownData} = useFetchGetAltsFinancialBreakdown(`riskbackofficereports/cricket/alternatives`);
    const { isLoadingParticulars, particularsData, serverErrorParticularsData } =useFetchGetParticularsData(`riskbackofficereports/football/particular`);
    const { isLoadingB2bData, b2bData, serverErrorB2bData } = useFetchGetB2bData(`riskbackofficereports/football/b2b`);
    const { isLoadingB2cData, b2cData, serverErrorB2cData } = useFetchGetB2cData(`riskbackofficereports/football/b2c`);

    const {isLoadingAltsByVolume,altsByVolumeData,serverErrorAltsByVolumeData} = useFetchGetAltsByVolumeData(`riskbackofficereports/tops/altsbyvolume`);
    const {isLoadingTradsByVolume,tradsByVolumeData,serverErrorTradsByVolumeData} = useFetchGetTradsByVolumeData(`riskbackofficereports/tops/tradesbyvolume`);
    const {isLoadingAltsByRevenueData,altsByRevenueData,serverErrorAltsByRevenueData} = useFetchGetAltsByRevenueData(`riskbackofficereports/tops/altsbyrevenue`);
    const {isLoadingTradsByRevenueData,tradsByRevenueData,serverErrorTradsByRevenueData} = useFetchGetTradsByRevenueData(`riskbackofficereports/tops/tradesbyrevenue`);
    const {isLoadingLossGeneratingAltsData,lossGeneratingAltsData,serverErrorLossGeneratingAltsData} = useFetchGetLossGeneratingAltsData(`riskbackofficereports/tops/lossgeneratingalts`);
    const {isLoadingLossGeneratingTradsData,lossGeneratingTradsData,serverErrorLossGeneratingTradsData} = useFetchGetLossGeneratingTradsData(`riskbackofficereports/tops/lossgeneratingtrades`);

    const {isLoadingTradsfinancialbreakdownData,tradsfinancialbreakdownData,serverErrorTradsfinancialbreakdownData} = useFetchGetTradesfinancialbreakdownData(`riskbackofficereports/tradesfinancialbreakdown`);
    const {isLoadingTradsfinancialbreakdownParticularData,tradsfinancialbreakdownParticularData,serverErrorTradsfinancialbreakdownParticularData} = useFetchGetTradesfinancialbreakdownParticularData(`riskbackofficereports/tradesfinancialbreakdown/particular`);

    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );

    //sortAstCard
    const [sortAstCard, setSortAstCard] = useState([{field: "daily",dir: "desc"}]);

    //sortAltsCard
    const [sortAltsCard, setSortAltsCard] = useState([{field: "daily",dir: "desc"}])

    //sortTradsCard
    const [sortTradsCard, setSortTradsCard] = useState([{field: "daily",dir: "desc"}]);

    //sortTotalNetDeposits
    const [sortTotalNetDeposits, setSortTotalNetDeposits] = useState([{field: "daily",dir: "desc"}]);

    //sortActivityBreakup
    const [sortActivityBreakup, setSortActivityBreakup] = useState([{field: "daily",dir: "desc"}]);

    //sortAltsFinancialBreakdown
    const [sortAltsFinancialBreakdown, setSortAltsFinancialBreakdown] = useState([{field: "rpm",dir: "desc"}]);

    //sortParticulars
    const [sortParticulars, setSortParticulars] = useState([{field: "yesterday",dir: "desc"}]);

    //sortB2b
    const [sortB2b, setSortB2b] = useState([{field: "pl",dir: "desc"}]);

    //sortB2c
    const [sortB2c, setSortB2c] = useState([{field: "pl",dir: "desc",}]);

    //sortTradsfinancialbreakdown
    const [sortTradsfinancialbreakdown, setSortTradsfinancialbreakdown] = useState([{field: "activeClients",dir: "desc"}]);

    //sortTradsfinancialbreakdownParticular
    const [sortTradsfinancialbreakdownParticular, setSortTradsfinancialbreakdownParticular] = useState([{field: "yesterday",dir: "desc"}]);

    //sortAltsByVolume
    const [sortAltsByVolume, setSortAltsByVolume] = useState([{field: "volume",dir: "desc",}]);

    //sortTradsByVolume
    const [sortTradsByVolume, setSortTradsByVolume] = useState([{field: "volume",dir: "desc"}]);

    //sortAltsByRevenue
    const [sortAltsByRevenue, setSortAltsByRevenue] = useState([{field: "volume",dir: "desc",}]);

    //sortTradsByRevenue
    const [sortTradsByRevenue, setSortTradsByRevenue] = useState([{field: "volume",dir: "desc"}]);

    //sortLossGeneratingAlts
    const [sortLossGeneratingAlts, setSortLossGeneratingAlts] = useState([{field: "volume",dir: "desc"}]);

    //sortLossGeneratingTrads
    const [sortLossGeneratingTrads, setSortLossGeneratingTrads] = useState([{ field: "volume", dir: "desc" }]);

    // Select tab
    const [selected, setSelected] = useState(0);

    const handleSelect = (e) => {
        setSelected(e.selected);
    };

    return (
        <div>
            <div className="page-content revenue-reports-wrapper">
                <h4>Dashboard</h4>
                <div>
                    <TabStrip selected={selected} onSelect={handleSelect} className="mt-4">
                        <TabStripTab title="Financial Headline">
                            <div className="section-heading alert alert-success">
                                <h5 className="p-0">Financial Headline</h5>
                            </div>
                            <div className="row p-0 custom-box-shadow">
                                <div className="col-6 p-3 position-relative">
                                    <h6>Top Cards</h6>
                                    {astcardData.length === 0 && loadingPanel}
                                    <Grid
                                        style={{
                                            height: "460px",
                                        }}
                                        sortable={true}
                                        reorderable={true}
                                        pageable={{
                                            buttonCount: 4,
                                            pageSizes: true,
                                        }}
                                        sort={sortAstCard}
                                        onSortChange={(e) => {
                                            setSortAstCard(e.sort);
                                        }}
                                        data={orderBy(astcardData, sortAstCard)}
                                    >
                                        <GridColumn field="alts" title="AST" width="170" />
                                        <GridColumn field="daily" title="Daily" cell={GridColorCell} />
                                        <GridColumn
                                            field="monthly"
                                            title="Monthly"
                                            cell={GridColorCell}
                                        />
                                        <GridColumn
                                            field="yearly"
                                            title="Yearly"
                                            cell={GridColorCell}
                                        />
                                    </Grid>
                                </div>

                                <div className="col-6 p-3 position-relative">
                                    <h6>Alternative Cards</h6>
                                    {altsCardData.length === 0 && loadingPanel}
                                    <Grid
                                        style={{
                                            height: "460px",
                                        }}
                                        sortable={true}
                                        reorderable={true}
                                        pageable={{
                                            buttonCount: 4,
                                            pageSizes: true,
                                        }}
                                        sort={sortAltsCard}
                                        onSortChange={(e) => {
                                            setSortAltsCard(e.sort);
                                        }}
                                        data={orderBy(altsCardData, sortAltsCard)}
                                    >
                                        <GridColumn field="alts" title="Alts" width="170" />
                                        <GridColumn field="daily" title="Daily" cell={GridColorCell} />
                                        <GridColumn
                                            field="monthly"
                                            title="Monthly"
                                            cell={GridColorCell}
                                        />
                                        <GridColumn
                                            field="yearly"
                                            title="Yearly"
                                            cell={GridColorCell}
                                        />
                                    </Grid>
                                </div>

                                <div className="col-6 p-3 position-relative">
                                    <h6>Traditional Assets</h6>
                                    {tradsCardData.length === 0 && loadingPanel}
                                    <Grid
                                        style={{
                                            height: "270px",
                                        }}
                                        sortable={true}
                                        reorderable={true}
                                        pageable={{
                                            buttonCount: 4,
                                            pageSizes: true,
                                        }}
                                        sort={sortTradsCard}
                                        onSortChange={(e) => {
                                            setSortTradsCard(e.sort);
                                        }}
                                        data={orderBy(tradsCardData, sortTradsCard)}
                                    >
                                        <GridColumn field="alts" title="Trads" width="170" />
                                        <GridColumn field="daily" title="Daily" cell={GridColorCell} />
                                        <GridColumn
                                            field="monthly"
                                            title="Monthly"
                                            cell={GridColorCell}
                                        />
                                        <GridColumn
                                            field="yearly"
                                            title="Yearly"
                                            cell={GridColorCell}
                                        />
                                    </Grid>
                                </div>

                                <div className="col-6 p-3 position-relative">
                                    <h6>Total Net Deposits</h6>
                                    {totalNetDepositsData.length === 0 && loadingPanel}
                                    <Grid
                                        style={{
                                            height: "270px",
                                        }}
                                        sortable={true}
                                        reorderable={true}
                                        pageable={{
                                            buttonCount: 4,
                                            pageSizes: true,
                                        }}
                                        sort={sortTotalNetDeposits}
                                        onSortChange={(e) => {
                                            setSortTotalNetDeposits(e.sort);
                                        }}
                                        data={orderBy(totalNetDepositsData, sortTotalNetDeposits)}
                                    >
                                        <GridColumn field="ast" title="AST" width="170" />
                                        <GridColumn field="daily" title="Daily" cell={GridColorCell} />
                                        <GridColumn
                                            field="monthly"
                                            title="Monthly"
                                            cell={GridColorCell}
                                        />
                                        <GridColumn
                                            field="yearly"
                                            title="Yearly"
                                            cell={GridColorCell}
                                        />
                                    </Grid>
                                </div>

                                <div className="col-12 p-3 position-relative">
                                    <h6>Activity Breakup</h6>
                                    {activityBreakupData.length === 0 && loadingPanel}
                                    <Grid
                                        style={{
                                            height: "270px",
                                        }}
                                        sortable={true}
                                        reorderable={true}
                                        pageable={{
                                            buttonCount: 4,
                                            pageSizes: true,
                                        }}
                                        sort={sortActivityBreakup}
                                        onSortChange={(e) => {
                                            setSortActivityBreakup(e.sort);
                                        }}
                                        data={orderBy(activityBreakupData, sortActivityBreakup)}
                                    >
                                        <GridColumn
                                            field="activity"
                                            title="Activity Breakup"
                                            width="170"
                                        />
                                        <GridColumn field="daily" title="Daily" cell={GridColorCell} />
                                        <GridColumn field="dailyActivity"  title="Daily Activity %" />
                                        <GridColumn
                                            field="mtd"
                                            title="MTD Activity"
                                            cell={GridColorCell}
                                        />
                                        <GridColumn field="mtdActivity" title="MTD Activity %" />
                                        <GridColumn field="ytd" title="YTD" cell={GridColorCell} />
                                        <GridColumn field="ytdActivity" title="YTD Activity %" />
                                    </Grid>
                                </div>
                            </div>
                        </TabStripTab>
                        <TabStripTab title="Alternative Financial Breakdown">
                            <div className="section-heading alert alert-success mt-3">
                                <h5 className="p-0">Alternative Financial Breakdown</h5>
                            </div>
                            <div className="row p-0 custom-box-shadow">
                                <div className="col-12 p-3 position-relative">
                                    <h6>Alternatives</h6>
                                    {altsFinancialBreakdownData.length === 0 && loadingPanel}
                                    <Grid
                                        style={{
                                            height: "420px",
                                        }}
                                        sortable={true}
                                        reorderable={true}
                                        pageable={{
                                            buttonCount: 4,
                                            pageSizes: true,
                                        }}
                                        sort={sortAltsFinancialBreakdown}
                                        onSortChange={(e) => {
                                            setSortAltsFinancialBreakdown(e.sort);
                                        }}
                                        data={orderBy(altsFinancialBreakdownData, sortAltsFinancialBreakdown)}
                                    >
                                        <GridColumn title="Consolidated">
                                            <GridColumn field="date" title="Date" width={180} />
                                        </GridColumn>

                                        <GridColumn title="Profitability Metrics">
                                            <GridColumn field="pl" title="P/L" cell={GridColorCell} />
                                            <GridColumn field="rpm" title="RPM" />
                                            <GridColumn field="winRatio" title="Win Ratio"  />
                                            <GridColumn
                                                field="riskPLRatio"
                                                title="Risk P/L Ratio"
                                                
                                            />
                                        </GridColumn>

                                        <GridColumn title="P/L Breakdown">
                                            <GridColumn
                                                field="openVolume"
                                                title="Open Volume"
                                                cell={GridColorCell}
                                                
                                            />
                                            <GridColumn
                                                field="openPLChange"
                                                title="Open P/L Change"
                                                cell={GridColorCell}
                                                
                                            />
                                            <GridColumn
                                                field="openPL"
                                                title="Open P/L"
                                                cell={GridColorCell}
                                                
                                            />
                                        </GridColumn>

                                        <GridColumn title="Closed P/L">
                                            <GridColumn
                                                field="commission"
                                                title="Commission"
                                                cell={GridColorCell}
                                                
                                            />
                                            <GridColumn
                                                field="spread"
                                                title="Spread"
                                                cell={GridColorCell}
                                                
                                            />
                                            <GridColumn
                                                field="riskPL"
                                                title="Risk P/L"
                                                cell={GridColorCell}
                                                
                                            />
                                            <GridColumn
                                                field="swap"
                                                title="Swap"
                                                cell={GridColorCell}
                                                
                                            />
                                            <GridColumn
                                                field="volume"
                                                title="Volume in $"
                                                cell={GridColorCell}
                                                
                                            />
                                            <GridColumn
                                                field="activeClient"
                                                title="Active Client"
                                                cell={GridColorCell}
                                                
                                            />
                                            <GridColumn
                                                field="dealCount"
                                                title="Deal Count"
                                                cell={GridColorCell}
                                                
                                            />
                                        </GridColumn>
                                    </Grid>
                                </div>

                                <div className="col-6 p-3 position-relative">
                                    <h6>Particulars</h6>
                                    {particularsData.length === 0 && loadingPanel}
                                    <Grid
                                        style={{
                                            height: "270px",
                                        }}
                                        sortable={true}
                                        reorderable={true}
                                        pageable={{
                                            buttonCount: 4,
                                            pageSizes: true,
                                        }}
                                        sort={sortParticulars}
                                        onSortChange={(e) => {
                                            setSortParticulars(e.sort);
                                        }}
                                        data={orderBy(particularsData, sortParticulars)}
                                    >
                                        <GridColumn field="particular" title="Particular" width={170} />
                                        <GridColumn
                                            field="yesterday"
                                            title="Yesterday"
                                            cell={GridColorCell}
                                        />
                                        <GridColumn
                                            field="dayBeforeYesterday"
                                            title="Day Before Yesterday"
                                            cell={GridColorCell}
                                            width={165}
                                        />
                                        <GridColumn
                                            field="variation"
                                            title="Variation"
                                            cell={GridColorCell}
                                        />
                                        <GridColumn field="growth" title="Growth" />
                                    </Grid>
                                </div>
                            </div>
                        </TabStripTab>
                        <TabStripTab title="B2B">
                            <div className="section-heading alert alert-success mt-3">
                                <h5 className="p-0">B2B</h5>
                            </div>
                            <div className="row p-0 custom-box-shadow">
                                <div className="col-12 p-3 position-relative">
                                    <h6>B2B</h6>
                                    {b2bData.length === 0 && loadingPanel}
                                    <Grid
                                        style={{
                                            height: "380px",
                                        }}
                                        sortable={true}
                                        reorderable={true}
                                        pageable={{
                                            buttonCount: 4,
                                            pageSizes: true,
                                        }}
                                        sort={sortB2b}
                                        onSortChange={(e) => {
                                            setSortB2b(e.sort);
                                        }}
                                        data={orderBy(b2bData, sortB2b)}
                                    >
                                        <GridColumn title="B2B">
                                            <GridColumn field="date" title="Date" width={150} />
                                        </GridColumn>

                                        <GridColumn title="Profitability Metrics">
                                            <GridColumn
                                                field="pl"
                                                title="P/L"
                                                cell={GridColorCell}
                                                width={100}
                                            />
                                            <GridColumn field="rpm" title="RPM" width={80} />
                                            <GridColumn field="winRatio" title="Win Ratio" width={100} />
                                            <GridColumn
                                                field="riskPLRatio"
                                                title="Risk P/L Ratio"
                                                width={130}
                                            />
                                        </GridColumn>

                                        <GridColumn title="P/L Breakdown">
                                            <GridColumn
                                                field="openVolume"
                                                title="Open Volume"
                                                cell={GridColorCell}
                                                width={120}
                                            />
                                            <GridColumn
                                                field="openPLChange"
                                                title="Open P/L Change"
                                                cell={GridColorCell}
                                                width={140}
                                            />
                                            <GridColumn
                                                field="openPL"
                                                title="Open P/L"
                                                cell={GridColorCell}
                                                width={110}
                                            />
                                        </GridColumn>

                                        <GridColumn title="Closed P/L">
                                            <GridColumn
                                                field="commission"
                                                title="Commission"
                                                cell={GridColorCell}
                                                width={120}
                                            />
                                            <GridColumn
                                                field="spread"
                                                title="Spread"
                                                cell={GridColorCell}
                                                width={100}
                                            />
                                            <GridColumn
                                                field="riskPL"
                                                title="Risk P/L"
                                                cell={GridColorCell}
                                                width={100}
                                            />
                                            <GridColumn
                                                field="swap"
                                                title="Swap"
                                                cell={GridColorCell}
                                                width={90}
                                            />
                                            <GridColumn
                                                field="volume"
                                                title="Volume in $"
                                                cell={GridColorCell}
                                                width={110}
                                            />
                                            <GridColumn
                                                field="activeClient"
                                                title="Active Clients"
                                                cell={GridColorCell}
                                                width={120}
                                            />
                                            <GridColumn
                                                field="dealCount"
                                                title="Deal Count"
                                                cell={GridColorCell}
                                                width={110}
                                            />
                                        </GridColumn>
                                    </Grid>
                                </div>
                            </div>
                        </TabStripTab>
                        <TabStripTab title="B2C">
                            <div className="section-heading alert alert-success mt-3">
                                <h5 className="p-0">B2C</h5>
                            </div>
                            <div className="row p-0 custom-box-shadow">
                                <div className="col-12 p-3 position-relative">
                                    <h6>B2C</h6>
                                    {b2cData.length === 0 && loadingPanel}
                                    <Grid
                                        style={{
                                            height: "380px",
                                        }}
                                        sortable={true}
                                        reorderable={true}
                                        pageable={{
                                            buttonCount: 4,
                                            pageSizes: true,
                                        }}
                                        sort={sortB2c}
                                        onSortChange={(e) => {
                                            setSortB2c(e.sort);
                                        }}
                                        data={orderBy(b2cData, sortB2c)}
                                    >
                                        <GridColumn title="B2C">
                                            <GridColumn field="date" title="Date" width={150} />
                                        </GridColumn>

                                        <GridColumn title="Profitability Metrics">
                                            <GridColumn
                                                field="pl"
                                                title="P/L"
                                                cell={GridColorCell}
                                                width={100}
                                            />
                                            <GridColumn field="rpm" title="RPM" width={80} />
                                            <GridColumn field="winRatio" title="Win Ratio" width={100} />
                                            <GridColumn
                                                field="riskPLRatio"
                                                title="Risk P/L Ratio"
                                                width={130}
                                            />
                                        </GridColumn>

                                        <GridColumn title="P/L Breakdown">
                                            <GridColumn
                                                field="openVolume"
                                                title="Open Volume"
                                                cell={GridColorCell}
                                                width={120}
                                            />
                                            <GridColumn
                                                field="openPLChange"
                                                title="Open P/L Change"
                                                cell={GridColorCell}
                                                width={140}
                                            />
                                            <GridColumn
                                                field="openPL"
                                                title="Open P/L"
                                                cell={GridColorCell}
                                                width={110}
                                            />
                                        </GridColumn>

                                        <GridColumn title="Closed P/L">
                                            <GridColumn
                                                field="commission"
                                                title="Commission"
                                                cell={GridColorCell}
                                                width={120}
                                            />
                                            <GridColumn
                                                field="spread"
                                                title="Spread"
                                                cell={GridColorCell}
                                                width={100}
                                            />
                                            <GridColumn
                                                field="riskPL"
                                                title="Risk P/L"
                                                cell={GridColorCell}
                                                width={100}
                                            />
                                            <GridColumn
                                                field="swap"
                                                title="Swap"
                                                cell={GridColorCell}
                                                width={90}
                                            />
                                            <GridColumn
                                                field="volume"
                                                title="Volume in $"
                                                cell={GridColorCell}
                                                width={110}
                                            />
                                            <GridColumn
                                                field="activeClient"
                                                title="Active Clients"
                                                cell={GridColorCell}
                                                width={120}
                                            />
                                            <GridColumn
                                                field="dealCount"
                                                title="Deal Count"
                                                cell={GridColorCell}
                                                width={110}
                                            />
                                        </GridColumn>
                                    </Grid>
                                </div>
                            </div>
                        </TabStripTab>
                        <TabStripTab title="Tops">
                            <div className="section-heading alert alert-success mt-3">
                                <h5 className="p-0">Tops</h5>
                            </div>
                            <div className="row p-0 custom-box-shadow">
                                <div className="col-6 p-3 position-relative">
                                    <h6>Top 5 Alternative by Volume</h6>
                                    {altsByVolumeData.length === 0 && loadingPanel}
                                    <Grid
                                        style={{
                                            height: "300px",
                                        }}
                                        sortable={true}
                                        reorderable={true}
                                        pageable={{
                                            buttonCount: 4,
                                            pageSizes: true,
                                        }}
                                        sort={sortAltsByVolume}
                                        onSortChange={(e) => {
                                            setSortAltsByVolume(e.sort);
                                        }}
                                        data={orderBy(altsByVolumeData, sortAltsByVolume)}
                                    >
                                        <GridColumn field="symbol" title="Symbol" />
                                        <GridColumn
                                            field="volume"
                                            title="Volume"
                                            cell={GridColorCell}
                                        />
                                        <GridColumn field="volumePercent" title="Volume %" />
                                    </Grid>
                                </div>

                                <div className="col-6 p-3 position-relative">
                                    <h6>Top 5 Trades by Volume</h6>
                                    {tradsByVolumeData.length === 0 && loadingPanel}
                                    <Grid
                                        style={{
                                            height: "300px",
                                        }}
                                        sortable={true}
                                        reorderable={true}
                                        pageable={{
                                            buttonCount: 4,
                                            pageSizes: true,
                                        }}
                                        sort={sortTradsByVolume}
                                        onSortChange={(e) => {
                                            setSortTradsByVolume(e.sort);
                                        }}
                                        data={orderBy(tradsByVolumeData, sortTradsByVolume)}
                                    >
                                        <GridColumn field="symbol" title="Symbol" />
                                        <GridColumn
                                            field="volume"
                                            title="Volume"
                                            cell={GridColorCell}
                                        />
                                        <GridColumn field="volumePercent" title="Volume %" />
                                    </Grid>
                                </div>

                                <div className="col-6 p-3 position-relative">
                                    <h6>Top 5 Revenue Generating Alternative</h6>
                                    {altsByRevenueData.length === 0 && loadingPanel}
                                    <Grid
                                        style={{
                                            height: "300px",
                                        }}
                                        sortable={true}
                                        reorderable={true}
                                        pageable={{
                                            buttonCount: 4,
                                            pageSizes: true,
                                        }}
                                        sort={sortAltsByRevenue}
                                        onSortChange={(e) => {
                                            setSortAltsByRevenue(e.sort);
                                        }}
                                        data={orderBy(altsByRevenueData, sortAltsByRevenue)}
                                    >
                                        <GridColumn field="symbol" title="Symbol" />
                                        <GridColumn
                                            field="revenue"
                                            title="Revenue"
                                            cell={GridColorCell}
                                        />
                                        <GridColumn
                                            field="volume"
                                            title="Volume"
                                            cell={GridColorCell}
                                        />
                                    </Grid>
                                </div>

                                <div className="col-6 p-3 position-relative">
                                    <h6>Top 5 Revenue Generating Trades</h6>
                                    {tradsByRevenueData.length === 0 && loadingPanel}
                                    <Grid
                                        style={{
                                            height: "300px",
                                        }}
                                        sortable={true}
                                        reorderable={true}
                                        pageable={{
                                            buttonCount: 4,
                                            pageSizes: true,
                                        }}
                                        sort={sortTradsByRevenue}
                                        onSortChange={(e) => {
                                            setSortTradsByRevenue(e.sort);
                                        }}
                                        data={orderBy(tradsByRevenueData, sortTradsByRevenue)}
                                    >
                                        <GridColumn field="symbol" title="Symbol" />
                                        <GridColumn
                                            field="revenue"
                                            title="Revenue"
                                            cell={GridColorCell}
                                        />
                                        <GridColumn
                                            field="volume"
                                            title="Volume"
                                            cell={GridColorCell}
                                        />
                                    </Grid>
                                </div>

                                <div className="col-6 p-3 position-relative">
                                    <h6>Top 5 Loss Generating Alternative</h6>
                                    {lossGeneratingAltsData.length === 0 && loadingPanel}
                                    <Grid
                                        style={{
                                            height: "300px",
                                        }}
                                        sortable={true}
                                        reorderable={true}
                                        pageable={{
                                            buttonCount: 4,
                                            pageSizes: true,
                                        }}
                                        sort={sortLossGeneratingAlts}
                                        onSortChange={(e) => {
                                            setSortLossGeneratingAlts(e.sort);
                                        }}
                                        data={orderBy(lossGeneratingAltsData, sortLossGeneratingAlts)}
                                    >
                                        <GridColumn field="symbol" title="Symbol" />
                                        <GridColumn field="loss" title="Loss" cell={GridColorCell} />
                                        <GridColumn
                                            field="volume"
                                            title="Volume"
                                            cell={GridColorCell}
                                        />
                                    </Grid>
                                </div>

                                <div className="col-6 p-3 position-relative">
                                    <h6>Top 5 Loss Generating Trades</h6>
                                    {lossGeneratingTradsData.length === 0 && loadingPanel}
                                    <Grid
                                        style={{
                                            height: "300px",
                                        }}
                                        sortable={true}
                                        reorderable={true}
                                        pageable={{
                                            buttonCount: 4,
                                            pageSizes: true,
                                        }}
                                        sort={sortLossGeneratingTrads}
                                        onSortChange={(e) => {
                                            setSortLossGeneratingTrads(e.sort);
                                        }}
                                        data={orderBy(lossGeneratingTradsData, sortLossGeneratingTrads)}
                                    >
                                        <GridColumn field="symbol" title="Symbol" />
                                        <GridColumn field="loss" title="Loss" cell={GridColorCell} />
                                        <GridColumn
                                            field="volume"
                                            title="Volume"
                                            cell={GridColorCell}
                                        />
                                    </Grid>
                                </div>
                            </div>
                        </TabStripTab>
                        <TabStripTab title="Trades Financial Breakdown">
                            <div className="section-heading alert alert-success mt-3">
                                <h5 className="p-0">Trades Financial Breakdown</h5>
                            </div>
                            <div className="row p-0 custom-box-shadow">
                                <div className="col-12 p-3 position-relative">
                                    <h6>Trades Financial breakdown</h6>
                                    {tradsfinancialbreakdownData.length === 0 && loadingPanel}
                                    <Grid
                                        style={{
                                            height: "380px",
                                        }}
                                        sortable={true}
                                        reorderable={true}
                                        pageable={{
                                            buttonCount: 4,
                                            pageSizes: true,
                                        }}
                                        sort={sortTradsfinancialbreakdown}
                                        onSortChange={(e) => {
                                            setSortTradsfinancialbreakdown(e.sort);
                                        }}
                                        data={orderBy(tradsfinancialbreakdownData, sortTradsfinancialbreakdown)}
                                    >
                                        <GridColumn title="Traditionals">
                                            <GridColumn field="date" title="Date" />
                                        </GridColumn>

                                        <GridColumn title="A Book">
                                            <GridColumn
                                                field="a_PL"
                                                title="P/L"
                                                cell={GridColorCell}
                                                // width={160}
                                            />
                                            <GridColumn field="a_Volume" title="Volume" 
                                            // width={140}
                                             />
                                            <GridColumn
                                                field="a_RPM"
                                                title="RPM"
                                                cell={GridColorCell}
                                                // width={120}
                                            />
                                        </GridColumn>

                                        <GridColumn title="B Book">
                                            <GridColumn
                                                field="b_PL"
                                                title="P/L"
                                                cell={GridColorCell}
                                                // width={160}
                                            />
                                            <GridColumn field="b_Volume" title="Volume" 
                                            // width={140}
                                             />
                                            <GridColumn
                                                field="b_RPM"
                                                title="RPM"
                                                cell={GridColorCell}
                                                // width={120}
                                            />
                                        </GridColumn>

                                        <GridColumn title="Activity">
                                            <GridColumn field="activeClients" title="Active Clients" />
                                            <GridColumn
                                                field="dealCounts"
                                                title="Deal Count"
                                                // width={120}
                                            />
                                        </GridColumn>
                                    </Grid>
                                </div>

                                <div className="col-6 p-3 position-relative">
                                    <h6>Particulars</h6>
                                    {tradsfinancialbreakdownParticularData.length === 0 &&
                                        loadingPanel}
                                    <Grid
                                        style={{
                                            height: "270px",
                                        }}
                                        sortable={true}
                                        reorderable={true}
                                        pageable={{
                                            buttonCount: 4,
                                            pageSizes: true,
                                        }}
                                        sort={sortTradsfinancialbreakdownParticular}
                                        onSortChange={(e) => {
                                            setSortTradsfinancialbreakdownParticular(e.sort);
                                        }}
                                        data={orderBy(tradsfinancialbreakdownParticularData, sortTradsfinancialbreakdownParticular)}
                                    >
                                        <GridColumn field="particular" title="Particular"  />
                                        <GridColumn
                                            field="yesterday"
                                            title="Yesterday"
                                            cell={GridColorCell}
                                        />
                                        <GridColumn
                                            field="dayBeforeYesterday"
                                            title="Day Before Yesterday"
                                            cell={GridColorCell}
                                        />
                                        <GridColumn
                                            field="variation"
                                            title="Variation"
                                            cell={GridColorCell}
                                        />
                                        <GridColumn field="growth" title="Growth" />
                                    </Grid>
                                </div>
                            </div>
                        </TabStripTab>
                     </TabStrip>
                </div>               
            </div>
        </div>
    );
};

export default RevenueReports;
